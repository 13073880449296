import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  IconButton,
  Image,
  Link as LinkChakra,
  Show,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { useUserAuth } from 'src/AppModule/hooks';
import { UserProviderContext } from 'src/AppModule/providers/UserProvider/UserProvider';
import { useI18n } from 'src/i18n';
import { TypeUser } from 'src/shared/api/models';
import SvgUser from 'src/shared/assets/icons/svg-user.svg';
import Logo from 'src/shared/assets/imgs/logo-portal-desenvolvedor-gertec-web.svg';
import { LinkItemProps, LinkItems } from '../LinkItems';
import NavItem from '../NavItem/NavItem';
import NavOption from '../NavOption/NavOption';
import { NavOptions, NavOptionsProps, NavOptionsWithSubOptionsProps } from '../NavOptions';

export default function NavBar() {
  const { user } = useContext(UserProviderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { translate } = useI18n();
  const { signOut } = useUserAuth();
  const location = useLocation();
  const isSmallScreen = useBreakpointValue({ base: true, md: false, lg: false, xl: false });
  const drawerWidth = useBreakpointValue({ base: '80%', sm: '380px', md: '380px' });

  useEffect(() => {
    if (!isSmallScreen && isOpen) {
      onClose();
    }
  }, [isSmallScreen, isOpen, onClose]);

  function canShowMenu(link: LinkItemProps) {
    return user && link.allowedUserTypes.includes(user.type) && link.canAccess(user) && !link.isHidden;
  }

  function canShowOption(option: NavOptionsProps | NavOptionsWithSubOptionsProps) {
    return user && option.allowedUserTypes.includes(user.type);
  }

  function renderUsername() {
    if (!user) {
      return '';
    }
    if (user.type !== TypeUser.company_common) {
      return user.companyName;
    }

    const nameSplitedBySpace = user.name.split(' ');
    const nameAndSecondName = nameSplitedBySpace.slice(0, 2);
    const lengthWordPreposition = 3;
    if (nameAndSecondName[nameAndSecondName.length - 1].length <= lengthWordPreposition) {
      nameAndSecondName?.push(nameSplitedBySpace[2]);
    }
    if (nameSplitedBySpace.length > nameAndSecondName.length) {
      return `${nameAndSecondName.join(' ')}...`;
    }
    return nameAndSecondName.join(' ');
  }

  const renderLinks = () => {
    return LinkItems.map(
      (link) =>
        canShowMenu(link) && (
          <Box onClick={onClose} key={link.name}>
            <NavItem
              icon={link.icon}
              url={link.url}
              display="flex"
              justifyContent="flex-start"
              height={50}
              paddingLeft={5}
              isSelected={link.url === location.pathname}
            >
              <Text fontSize="sm" fontWeight="normal" color="blue.800">
                {translate(`app.menus.${link.name}`)}
              </Text>
            </NavItem>
          </Box>
        )
    );
  };

  return (
    <Flex px={4} bgColor="blue.800" h={16} alignItems="center" justifyContent="space-between">
      <Flex gap={4} alignItems="center" flex="1">
        <Show below="md">
          <IconButton onClick={onOpen} aria-label="open menu" icon={<HamburgerIcon w="24px" h="24px" />} />
        </Show>
        <LinkChakra as={Link} to="/home">
          <Image
            w={215}
            h={34}
            src={`${process.env.REACT_APP_PUBLIC_AWS_S3_PUBLIC_BUCKET}/logo-portal-desenvolvedor-gertec-web-frontend.svg`}
          />
        </LinkChakra>
      </Flex>

      <Show above="md">
        {!!NavOptions.length && (
          <Flex alignItems="center" justifyContent="center" flex="5">
            <Flex>
              {NavOptions.filter((i) => canShowOption(i)).map((i) => {
                if ('component' in i) {
                  return i.component(i.label, i.links);
                }

                return (
                  <NavOption
                    key={i.label}
                    icon={i.icon}
                    url={i.url}
                    isSelected={false}
                    name={translate(i.label)}
                    isExternal={i.isExternal}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </Show>

      <Show above="md">
        <LinkChakra as={Link} to="/company" textAlign="right" fontWeight="semibold" px="4" color="white" flex="1">
          {renderUsername()}
        </LinkChakra>
      </Show>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerContent maxW={drawerWidth}>
          <DrawerHeader pl={5}>
            <Flex alignItems="center" flexDirection="row" justifyContent="flex-start">
              <Box>
                <Image w="15px" h="15px" src={SvgUser} />
              </Box>
              <Text color="blue.800" fontWeight="semibold" fontSize="md" ml={2}>
                {renderUsername()}
              </Text>
            </Flex>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody p={0}>
            <Box>{user && renderLinks()}</Box>
          </DrawerBody>
          <DrawerFooter justifyContent="flex-start">
            <Flex onClick={signOut}>
              <NavItem url="" icon={FiLogOut} display="flex" height={35}>
                <Text fontSize="sm" fontWeight="normal">
                  {translate('app.actions.signOut')}
                </Text>
              </NavItem>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
