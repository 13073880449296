import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['checkbox', 'control', 'label', 'icon']);

export const Checkbox = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      background: 'white',
      borderColor: 'blue.900',
      border: '1px solid',
      borderRadius: '3px',
      opacity: '1',
      _checked: {
        _hover: null,
        bgColor: 'blue.900',
        color: 'white',
        borderColor: 'blue.900'
      }
    }
  },
  sizes: {
    md: {
      label: {
        fontSize: 'xs'
      },
      control: {
        height: '18px',
        width: '18px'
      }
    },
    xs: {
      label: {
        fontSize: 'xs'
      },
      control: {
        height: '18px',
        width: '18px'
      },
      icon: {
        padding: '3px'
      }
    },
    xl: {
      label: {
        fontSize: 'md'
      },
      control: {
        height: '18px',
        width: '19px'
      },
      icon: {
        padding: '3px'
      }
    }
  },
  defaultProps: {
    size: 'md'
  }
});
