export const keysDocumentEnUs = {
  'document.category.payment': 'Payment options',
  'document.category.automation': 'Automation',
  'document.category.selectCategory': 'Select a category',
  'document.title': 'Development Articles',
  'document.description':
    'Search for the latest articles and documentation, including sample code, tutorials, and API references.',
  'document.projectFilter.option': 'Select a project (optional)',
  'document.inputSearchWord.loadingMessage': 'Looking for articles...',
  'document.inputSearchWord.notFoundArticles': 'Information not found',
  'document.inputSearchWord.placeholder': 'Search',
  'document.error.forbidden.title': 'You are not allowed to view this documentation',
  'document.error.forbidden.description': 'Check the company profile permissions'
};
