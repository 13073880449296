import { Link, Icon } from '@chakra-ui/react';
import { FaFileDownload } from 'react-icons/fa';
import * as Styles from './styles';

const downloadFileIcon = <Icon as={FaFileDownload} boxSize={5} color="blue.600" />;

export default function LinkToDownload({ href, placeholder }: { href: string; placeholder: string }) {
  return (
    <Link href={href} isExternal style={{ textDecoration: 'none' }}>
      <Styles.FileButton leftIcon={downloadFileIcon}>
        <Styles.FileButtonText>{placeholder}</Styles.FileButtonText>
      </Styles.FileButton>
    </Link>
  );
}
