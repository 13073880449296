import { Box, Text, VStack } from '@chakra-ui/react';
import { useI18n } from 'src/i18n';
import { FiLogOut } from 'react-icons/fi';
import { useUserAuth } from 'src/AppModule/hooks';
import { useLocation } from 'react-router-dom';
import { LinkItemProps, LinkItems } from '../LinkItems';
import NavItem from '../NavItem/NavItem';
import NavButton from '../NavButton/NavButton';
import version from '../../../../version.json';

import * as Styles from './styles';

const SideBar = () => {
  const { translate } = useI18n();
  const location = useLocation();
  const { signOut, user } = useUserAuth();

  const removeProdLabel = () => {
    if (version.version.startsWith('prod-')) {
      return version.version.slice('prod-'.length);
    }
    return version.version;
  };

  const renderLinks = (link: LinkItemProps) => {
    const canShowMenu = link.allowedUserTypes.includes(user!.type) && link.canAccess(user!) && !link.isHidden;

    const isSelected = link.url === location.pathname;

    return (
      canShowMenu && (
        <NavItem key={link.name} icon={link.icon} url={link.url} isSelected={isSelected}>
          <Text fontSize="xs" fontWeight={isSelected ? 'bold' : 'medium'} color="blue.800" textAlign="center">
            {translate(`app.menus.${link.name}`)}
          </Text>
        </NavItem>
      )
    );
  };

  return (
    <Styles.Container>
      <VStack pt={6}>{user && LinkItems.map(renderLinks)}</VStack>
      <VStack py={4}>
        <NavButton icon={FiLogOut} iconColor="blue.800" onClick={signOut} fontSize={14} fontWeight={400}>
          {translate('app.actions.signOut')}
        </NavButton>
        <VStack fontSize={10} direction="row">
          <Box color="gray.500">{removeProdLabel()}</Box>
        </VStack>
      </VStack>
    </Styles.Container>
  );
};

export default SideBar;
