export const DropdownMenu = (props: JSX.IntrinsicElements['div']) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 0,
        marginTop: 8,
        position: 'absolute',
        width: 300,
        zIndex: 2
      }}
      {...props}
    />
  );
};
