import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { appAxios } from '../../axios';

export function createGetRequest<TResponse>(requestConfig: {
  url: string;
  queryParams?: object;
  axiosRequestConfig?: AxiosRequestConfig;
}) {
  const aborter = new AbortController();
  const response = appAxios
    .get<TResponse>(requestConfig.url, {
      ...requestConfig.axiosRequestConfig,
      signal: requestConfig.axiosRequestConfig?.signal || aborter.signal,
      params: requestConfig.queryParams,
      paramsSerializer: {
        serialize: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
    .then((response) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
}

export function createPostRequest<TResponse>(requestConfig: {
  url: string;
  body?: object | FormData;
  axiosRequestConfig?: AxiosRequestConfig;
}) {
  const aborter = new AbortController();
  const response = appAxios
    .post<TResponse>(requestConfig.url, requestConfig.body, {
      ...requestConfig.axiosRequestConfig,
      signal: requestConfig.axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
}

export function createDeleteRequest<TResponse>(requestConfig: {
  url: string;
  axiosRequestConfig?: AxiosRequestConfig;
}) {
  const aborter = new AbortController();
  const response = appAxios
    .delete<TResponse>(requestConfig.url, {
      ...requestConfig.axiosRequestConfig,
      signal: requestConfig.axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
}

export async function requestPost<TResponse, TRequest>(
  url: string,
  data: TRequest,
  aborter: AbortController,
  axiosRequestConfig?: AxiosRequestConfig
) {
  return appAxios
    .post<TResponse>(url, data, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);
}

export async function requestPut<TResponse, TRequest>(
  url: string,
  data: TRequest,
  aborter: AbortController,
  axiosRequestConfig?: AxiosRequestConfig
) {
  return appAxios
    .put<TResponse>(url, data, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);
}
