import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { FormTitle } from 'src/shared/components';

interface CustomCardHeaderProps {
  title: React.ReactNode;
  subtitle?: string;
}
const HeaderTitle: React.FC<CustomCardHeaderProps> = ({ title, subtitle }: CustomCardHeaderProps) => {
  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <FormTitle textContent="//" headingProps={{ color: 'yellow.800', mr: '2' }} />
        <FormTitle textContent={title} headingProps={{ fontWeight: 'semibold' }} />
        <Text ml={3}>{subtitle}</Text>
      </Flex>
    </Flex>
  );
};

export default HeaderTitle;
