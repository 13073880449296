import { Flex, Button, Text, Heading, Image, VStack, Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n';
import { APIModels } from 'src/shared/api';
import { ProgressStepper } from 'src/shared/components';
import WelcomeUser from 'src/shared/assets/imgs/welcome-questionnaire-user.svg';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import { createBlankAnswersFromQuestions, updateAnswers } from '../utils';

interface SurveyFormProps {
  survey: APIModels.ISurveyDTO;
  onSurveyAnswered: (answers: APIModels.IAnswerQuestionDTO[], onClose?: () => void) => void;
  onCloseSurvey?: () => void;
  observableOnClose: boolean;
}

export default function SurveyForm({ survey, onSurveyAnswered, onCloseSurvey, observableOnClose }: SurveyFormProps) {
  const { translate } = useI18n();
  const [answerQuestions, setAnswerQuestions] = useState<APIModels.IAnswerQuestionDTO[]>(
    createBlankAnswersFromQuestions(survey.questions)
  );
  const [indexQuestion, setIndexQuestion] = useState(0);
  const currentQuestion = survey.questions[indexQuestion];
  const currentAnswer = answerQuestions[indexQuestion];
  const [isAnswerValid, setIsAnswerValid] = useState(false);
  const totalQuestions = survey.questions.length;
  const [isInWelcomeStage, setIsInWelcomeStage] = useState(true);

  useEffect(() => {
    setAnswerQuestions(createBlankAnswersFromQuestions(survey.questions));
    setIndexQuestion(0);
    setIsInWelcomeStage(true);
  }, [observableOnClose]);

  useEffect(() => {
    if (!currentAnswer) {
      setIsAnswerValid(false);
      return;
    }
    if (currentAnswer.answers.length === 0) {
      setIsAnswerValid(false);
      return;
    }
    const customAlternatives = currentQuestion.alternatives.filter(
      (alternative) => alternative.type === APIModels.TypeAlternative.custom
    );
    const hasCustomInput = currentAnswer.answers
      .filter((answer) => customAlternatives.find((custom) => custom.id === answer.id))
      .every((answer) => answer.customValue && answer.customValue.length > 0);
    setIsAnswerValid(hasCustomInput);
  }, [currentAnswer]);

  function toGoBack() {
    if (indexQuestion === 0) {
      setIsInWelcomeStage(true);
      return;
    }
    setIndexQuestion((prev) => prev - 1);
  }

  function handleAnswerQuestion(questionId: number, alternatives: APIModels.IAnswerAlternativeDTO[]) {
    setAnswerQuestions(updateAnswers(answerQuestions, questionId, alternatives));
  }

  if (isInWelcomeStage) {
    return <WelcomeToSurvey onClick={() => setIsInWelcomeStage(false)} />;
  }

  return (
    <>
      <ArrowBackIcon
        cursor="pointer"
        position="absolute"
        top="30px"
        boxSize={6}
        color="black"
        onClick={() => toGoBack()}
      />
      <Flex justifyContent="space-between" h="full" flexDir="column" flex="1">
        <VStack>
          <Box w="2xs" mb="12">
            <Text textAlign="center" mb="5">
              {survey.title}
            </Text>
            <ProgressStepper currentItems={indexQuestion + 1} totalItems={totalQuestions} />
          </Box>

          <SurveyQuestion question={currentQuestion} answer={currentAnswer} onAnswer={handleAnswerQuestion} />
        </VStack>

        <Flex justifyContent="center" mt="3">
          {indexQuestion < totalQuestions - 1 && (
            <Button w="3xs" onClick={() => setIndexQuestion((prev) => prev + 1)} isDisabled={!isAnswerValid}>
              {translate('app.label.next')}
            </Button>
          )}

          {indexQuestion >= totalQuestions - 1 && (
            <Button
              w="3xs"
              onClick={onSurveyAnswered.bind(null, answerQuestions, onCloseSurvey)}
              isDisabled={!isAnswerValid}
            >
              {translate('app.label.finish')}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
}

function WelcomeToSurvey({ onClick }: { onClick: () => void }) {
  const { translate } = useI18n();

  return (
    <Flex
      flexDir="column"
      h="full"
      flex="1"
      textAlign="center"
      px={{ base: 4, md: 0 }}
      alignItems="center"
      justifyContent={{ base: 'center', lg: 'space-between' }}
      gap={{ base: 10, lg: 0 }}
    >
      <Image boxSize={40} src={WelcomeUser} />
      <Heading size={{ base: 'md', md: 'md', lg: 'lg' }} color="gray.900" fontWeight={500}>
        {translate('survey.modal.welcomeTitle')}
      </Heading>
      <Text fontSize="sm" align="center">
        {translate('survey.modal.welcomeDescription')}
      </Text>
      <Button w="3xs" paddingY={4} onClick={onClick}>
        {translate('survey.modal.start')}
      </Button>
    </Flex>
  );
}
