import { AxiosRequestConfig } from 'axios';
import { ISurveyDTO, IAnswerDTO } from '../../models';
import { createGetRequest, createPostRequest } from '../shared';

export const URLS = {
  get: () => '/surveys/current',
  submitAnswers: () => '/surveys/answers'
};

export const get = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ISurveyDTO | null>({ url: URLS.get(), axiosRequestConfig });
};

export const submitAnswers = (data: IAnswerDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  return createPostRequest<IAnswerDTO | null>({ url: URLS.submitAnswers(), body: data, axiosRequestConfig });
};
