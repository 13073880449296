import { Button, ButtonProps, Input } from '@chakra-ui/react';
import { InputProps } from 'chakra-react-select';
import styled from 'styled-components';

export const DotsBtn = styled(Button).attrs<ButtonProps>({
  tabIndex: -1,
  pointerEvents: 'none',
  h: '30px',
  w: '30px',
  variant: 'outline',
  colorScheme: 'gray',
  rounded: 'base',
  borderColor: 'chakraGray.400',
  color: 'blue.600',
  _before: {
    content: '"..."'
  }
} as ButtonProps)`` as typeof Button;

export const PageBtn = styled(Button).attrs<ButtonProps>({
  variant: 'outline',
  h: '30px',
  w: '30px',
  colorScheme: 'gray',
  rounded: 'base',
  color: 'blue.600',
  borderColor: 'chakraGray.400'
} as ButtonProps)`` as typeof Button;

export const NumberBtn = styled(Button).attrs<ButtonProps>({
  rounded: 'base',
  flexShrink: 1,
  cursor: 'pointer',
  variant: 'outline'
})`` as typeof Button;

export const CurrentPage = styled(Input).attrs<InputProps>({
  textAlign: 'center',
  variant: 'unstyled',
  minWidth: 'none',
  fontWeight: 700,
  type: 'number',
  color: 'blue.500',
  fontSize: 14
})`` as typeof Input;
