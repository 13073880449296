import { defineStyleConfig } from '@chakra-ui/react';

export const Input = defineStyleConfig({
  variants: {
    outline: {
      field: {
        bg: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.100',
        _placeholder: {
          color: 'gray.200'
        }
      }
    },
    flushed: {
      field: {
        borderColor: 'blackAlpha.200'
      }
    }
  },
  defaultProps: {
    variant: 'outline'
  }
});
