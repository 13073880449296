import { Flex, FlexProps } from '@chakra-ui/react';

const WrapperPage: React.FC<{ children: React.ReactNode } & FlexProps> = ({ children, ...props }) => {
  return (
    <Flex direction="column" h="full" mx={{ base: '6', md: '12' }} {...props}>
      {children}
    </Flex>
  );
};

export default WrapperPage;
