import React, { useEffect } from 'react';
import { Box, RadioGroup, Radio, Grid, GridItem } from '@chakra-ui/react';
import { IAnswerAlternativeDTO } from 'src/shared/api/models';
import { SurveyAlternativesProps } from '../interfaces';

export default function SurveyRadio({ alternatives, selected, onChange }: SurveyAlternativesProps) {
  const [values, setValues] = React.useState<IAnswerAlternativeDTO[]>([]);

  useEffect(() => {
    if (selected.length > 0) {
      updateRadioValue(selected[0]?.id.toString());
    }
  }, [alternatives]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  function updateRadioValue(e: string) {
    const updateOption: IAnswerAlternativeDTO = {
      id: parseInt(e, 10)
    };
    setValues([updateOption]);
  }

  return (
    <Box color="blue.900" minW={{ base: '480px', md: '800px' }} px={5}>
      <RadioGroup onChange={(e) => updateRadioValue(e)} value={values[0]?.id.toString()}>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {alternatives?.map((a) => {
            return (
              <GridItem key={a.id} w="full" h="full">
                <Radio value={a.id.toString()}>{a.description}</Radio>
              </GridItem>
            );
          })}
        </Grid>
      </RadioGroup>
    </Box>
  );
}
