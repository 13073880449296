import { Box, Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { MdEdit } from 'react-icons/md';

interface CompanyCardFieldProps {
  children?: React.ReactNode;
  isEditable?: boolean;
}
const CompanyCardField: React.FC<CompanyCardFieldProps> = ({ children, isEditable = false }: CompanyCardFieldProps) => {
  return (
    <Box ml="6" my={3}>
      <Flex alignItems="center">
        <Box w="100%">{children}</Box>
        {isEditable && (
          <Box ml={4}>
            <Icon w={5} h={5} as={MdEdit} color="blue.600" />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default CompanyCardField;
