import { useState } from 'react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Button, useToken } from '@chakra-ui/react';
import UiSelect from '../UiSelect/UiSelect';
import { Dropdown } from './components/Dropdown';
import { Option } from './components/Option';
import { Menu } from './components/Menu';
import { CustomSelectOption, CustomSelectGroupOptions } from './interfaces';
import { MenuList } from './components/MenuList';

type CustomSelectProps<TValue> = {
  placeholder: string;
  onChangeValue: (options: CustomSelectOption<TValue>[]) => void;
  currentValue: CustomSelectOption<TValue>[];
  options: CustomSelectGroupOptions<TValue>[] | CustomSelectOption<TValue>[];
  label: string;
};

const SearchIconDropdown = () => <SearchIcon color="gray.300" h={6} w={9} />;

function CustomSelect<TValue>({ placeholder, options, onChangeValue, currentValue, label }: CustomSelectProps<TValue>) {
  const [isOpen, setIsOpen] = useState(false);
  const [gray200] = useToken('colors', ['gray.200']);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          rightIcon={<ChevronDownIcon />}
          onClick={() => setIsOpen((prev) => !prev)}
          variant="outline"
          borderColor="gray.200"
          color="gray.700"
          borderRadius="4px"
          fontWeight="normal"
          justifyContent="space-between"
          w="full"
        >
          {label}
        </Button>
      }
    >
      <Box
        style={{
          border: `1px solid ${gray200}`,
          height: 400,
          borderRadius: 5
        }}
      >
        <UiSelect<CustomSelectOption<TValue>, true, CustomSelectGroupOptions<TValue>>
          autoFocus
          backspaceRemovesValue={false}
          components={{
            DropdownIndicator: SearchIconDropdown,
            Menu,
            Option,
            MenuList
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          isMulti
          focusBorderColor="transparent"
          colorScheme="transparent"
          selectedOptionColor="transparent"
          menuIsOpen
          onChange={(newValue) => {
            onChangeValue([...newValue]);
          }}
          options={options}
          placeholder={placeholder}
          styles={{
            control: (provided) => ({
              ...provided,
              minWidth: 440,
              margin: 8,
              boxShadow: 'none',
              border: 'none'
            }),
            menu: (provided) => ({
              ...provided,
              boxShadow: 'none'
            }),
            menuList: (provided) => ({
              ...provided,
              borderRadius: 0,
              boxShadow: 'none',
              borderTop: `1px solid ${gray200}`,
              borderBottom: `1px solid ${gray200}`
            })
          }}
          tabSelectsValue={false}
          value={currentValue}
        />
      </Box>
    </Dropdown>
  );
}

export default CustomSelect;
