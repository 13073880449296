import { AxiosError } from 'axios';

export default class ApiUtils {
  static translateError(error: unknown, separator: ':' | '.' = ':') {
    const unknownErrorKey = `errors${separator}unknownError`;
    if (!(error instanceof AxiosError)) {
      return unknownErrorKey;
    }

    const errors = error.response?.data;
    if (!Array.isArray(errors)) {
      return unknownErrorKey;
    }

    if (errors.length === 0) {
      return unknownErrorKey;
    }

    const errorResponse = errors[0];

    return `errors${separator}${errorResponse.code}`;
  }

  static translateErrorJsonKey(error: unknown) {
    return ApiUtils.translateError(error, ':');
  }

  static translateErrorObjectKey(error: unknown) {
    return ApiUtils.translateError(error, '.');
  }
}
