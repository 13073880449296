import { AxiosRequestConfig } from 'axios';
import { appAxios } from '../../axios';
import {
  ICreateNdaParams,
  IListNdasCompanyDTO,
  IListNdasParams,
  IGertecVersionNdasResponseDTO,
  IGertecListNdasParams,
  ISendNdaParams,
  IGertecListNdasResponseDTO,
  IGertecApproveNdaParams,
  INdaLatestDTO,
  IGertecNdaVersionsParams
} from '../../models';
import { createGetRequest, createPostRequest, requestPut } from '../shared';

export const URLS = {
  Gertec: {
    listNdas: () => '/gertec/ndas',
    ndaVersions: () => '/gertec/nda-versions',
    approve: (id: number) => `gertec/ndas/${id}/approve`,
    disapprove: (id: number) => `gertec/ndas/${id}/disapproval`
  },
  Company: {
    list: () => '/ndas',
    create: () => '/ndas'
  },
  Common: {
    ndaLatest: () => '/nda-latest',
    ndaLatestDownload: () => `${appAxios.getUri()}/ndas/nda-latest/download`,
    ndaDownload: () => `/ndas/download`
  }
};

export const getNdaLatestVersion = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<INdaLatestDTO>({ url: URLS.Common.ndaLatest(), queryParams: {}, axiosRequestConfig });
};

export const list = (data: IListNdasParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListNdasCompanyDTO>({ url: URLS.Company.list(), queryParams: data, axiosRequestConfig });
};

export const create = (data: ICreateNdaParams, axiosRequestConfig?: AxiosRequestConfig) => {
  const formData = new FormData();
  formData.append('idNda', data.idNda.toString());
  formData.append('nda', data.nda);
  formData.append('description', data.ndaDescription.toString());
  return createPostRequest<void>({ url: URLS.Company.create(), body: formData, axiosRequestConfig });
};

export const gertecUpdateNda = (data: ISendNdaParams, axiosRequestConfig?: AxiosRequestConfig) => {
  const formData = new FormData();
  formData.append('nda', data.nda);
  formData.append('description', data.description);
  formData.append('version', data.version);

  return createPostRequest<void>({ url: URLS.Gertec.ndaVersions(), body: formData, axiosRequestConfig });
};

export const gertecListNdas = (data: IGertecListNdasParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IGertecListNdasResponseDTO>({
    url: URLS.Gertec.listNdas(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const downloadNda = (key: string, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<Blob | MediaSource>({
    url: URLS.Common.ndaDownload(),
    queryParams: { key },
    axiosRequestConfig: { ...axiosRequestConfig, responseType: 'blob' }
  });
};

export const gertecVersionNdas = (data: IGertecNdaVersionsParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IGertecVersionNdasResponseDTO>({
    url: URLS.Gertec.ndaVersions(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const gertecApproveNda = ({ id }: IGertecApproveNdaParams, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = requestPut(URLS.Gertec.approve(id), {}, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const gertecDisapproveNda = (
  { id, reason }: IGertecApproveNdaParams,
  axiosRequestConfig?: AxiosRequestConfig
) => {
  const aborter = new AbortController();
  const response = requestPut(URLS.Gertec.disapprove(id), { reason }, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};
