export const keysMigratesPtBr = {
  'migrates.view.subtitle': 'Migre empresas anexando uma base dados em arquivo do Excel',
  'migrates.alert.success':
    'Todos os dados submetidos foram validados com sucesso. Foi enviado um convite por e-mail à cada empresa para concluir o cadastro.',
  'migrates.alert.error':
    'Há dados inválidos submetidos abaixo. Identifique os erros, corrija-os e envie novamente o arquivo.',
  'migrates.card.title': 'Upload de arquivo',
  'migrates.card.subtitle': 'Adicione o arquivo do excel (.XLS ou .CSV) clicando no botão abaixo:',
  'migrates.instructions.stepDescription': {
    '1': 'Faça download do modelo de arquivo do excel (.xls) clicando no botão abaixo:',
    '2': 'Preencha cada coluna conforme tipo de dado informado abaixo.'
  },
  'migrates.instructions.stepDescriptionPartials': {
    '2': 'A coluna do arquivo modelo não pode ser alterado:'
  },
  'migrates.instructions.step': {
    '1': 'Passo 1:',
    '2': 'Passo 2:'
  },
  'migrates.instructions.title': 'Instrução para preenchimento do arquivo',
  'migrates.instructions.subtitle':
    ' Serão validados os campos abaixo no arquivo do excel (.xls ou .csv), seguindo as respectivas nomenclaturas nos exemplos abaixo:',
  'migrates.instructions.label': {
    cnpj: 'CNPJ:',
    companyName: 'Nome da empresa:',
    representativeEmail: 'E-mail do representante:',
    representativeName: 'Nome do representante',
    telephone: 'Telefone:',
    domain: 'Dominio:'
  },
  'migrates.instructions.labelDescription': {
    cnpj: 'números com ou sem pontuação;',
    companyName: 'letras e números;',
    representativeEmail: 'um email válido;',
    representativeName: 'letras;',
    telephone: 'um telefone válido, deve conter o DDD com ou sem pontuação;',
    domain: 'o domínio é baseado no email utilizado pela empresa, ou seja, tudo aquilo que vem depois do @.'
  },
  'migrate.table.column': {
    cnpj: 'CNPJ',
    company: 'Empresa',
    emailLegalRepresentative: 'E-mail do representante',
    legalRepresentative: 'Representante Legal',
    telephone: 'Telefone',
    domain: 'Domínio informado',
    status: 'Status'
  },
  'migrates.statusRow': {
    valid: 'Válido',
    invalid: 'Inválido',
    standBy: 'Aguardando envio'
  },
  'migrate.failValidate': 'Falha na validação dos dados do arquivo',
  'migrate.successValidate': 'Arquivo validado com sucesso.',
  'migrate.inputUpload.placeholder': 'Clique aqui para adicionar o arquivo',
  'migrate.inputDownload.placeholder': 'Clique aqui para fazer download do arquivo modelo'
};
