import { useI18n } from 'src/i18n';
import { RangeDatepicker, RangeDatepickerProps } from './external/range';
import { propsConfig } from './propsConfig';

const UiRangeDatePicker: React.FC<RangeDatepickerProps> = ({ ...props }) => {
  const { translate } = useI18n();

  return (
    <RangeDatepicker
      {...props}
      propsConfigs={{
        ...propsConfig,
        ...props.propsConfigs
      }}
      configs={{
        dateFormat: 'dd/MM/yyyy',
        dayNames: [
          translate('clients.months.sunday'),
          translate('clients.months.monday'),
          translate('clients.months.tuesday'),
          translate('clients.months.wednesday'),
          translate('clients.months.thursday'),
          translate('clients.months.friday'),
          translate('clients.months.saturday')
        ],
        monthNames: [
          translate('clients.months.january'),
          translate('clients.months.february'),
          translate('clients.months.march'),
          translate('clients.months.april'),
          translate('clients.months.may'),
          translate('clients.months.june'),
          translate('clients.months.july'),
          translate('clients.months.august'),
          translate('clients.months.september'),
          translate('clients.months.october'),
          translate('clients.months.november'),
          translate('clients.months.december')
        ]
      }}
    />
  );
};

export default UiRangeDatePicker;
