import { AxiosRequestConfig } from 'axios';
import { IProjectAccessDTO, IProjectDTO } from '../../models/dtos/IProjectDTO';
import { createGetRequest } from '../shared';

export const URLS = {
  listProjectAccess: () => `/projects-access`,
  listProjects: () => `/projects`
};

export function listProjectAccess(axiosRequestConfig?: AxiosRequestConfig) {
  return createGetRequest<IProjectAccessDTO[]>({ url: URLS.listProjectAccess(), axiosRequestConfig });
}

export function listProjects(axiosRequestConfig?: AxiosRequestConfig) {
  return createGetRequest<IProjectDTO[]>({ url: URLS.listProjects(), axiosRequestConfig });
}
