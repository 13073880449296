export const keysClientEnUs = {
  'clients.view.subtitle': 'Registered companies list',

  'clients.section.generalInfo': 'Company Information',
  'clients.section.legalRepresentative': 'Legal Representative',
  'clients.section.address': 'Address',

  'clients.field.generalInfo.totalUsers': 'Users',
  'clients.field.generalInfo.totalTeams': 'Teams',
  'clients.field.generalInfo.documentId': 'CNPJ/TAXID',
  'clients.field.generalInfo.name': 'Company name',
  'clients.field.generalInfo.segment': 'Segment',
  'clients.field.generalInfo.domains': 'Domains',
  'clients.field.generalInfo.created': 'Creation date',
  'clients.field.status': 'Status',
  'clients.field.generalInfo.active': 'Active',
  'clients.field.generalInfo.waiting_complete_registration': 'Waiting to complete registration',

  'clients.field.legalRepresentative.name': 'Name',
  'clients.field.legalRepresentative.email': 'E-mail',
  'clients.field.legalRepresentative.phone': 'Phone',
  'clients.field.legalRepresentative.active': 'Active',
  'clients.field.legalRepresentative.rejected': 'rejected',
  'clients.field.legalRepresentative.waiting_approval': 'Waiting for approval',
  'clients.field.legalRepresentative.waiting_confirm_registration': 'Waiting to confirm registration',

  'clients.field.address.zipCode': 'Zip code',
  'clients.field.address.addressLine1': 'Address Line 1',
  'clients.field.address.addressLine2': 'Address Line 2',
  'clients.field.address.district': 'District',
  'clients.field.address.city': 'City',
  'clients.field.address.state': 'State/Province',
  'clients.field.address.additionalInformation': 'Additional Information (opcional)',
  'clients.field.empty': 'Empty',
  'clients.request.error': 'Client not found'
};
