import { ButtonProps, HStack, Icon, IconButton, IconButtonProps, StackProps } from '@chakra-ui/react';
import { memo, useCallback, useMemo } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import * as Styles from './styles';

const RULER_SIZE = 5;

export interface UiPaginatorProps extends StackProps {
  colorScheme?: ButtonProps['colorScheme'];
  size?: ButtonProps['size'];
  totalPages: number;
  page?: number;
  onPageChange?: (page: number) => unknown;
  isHidden?: boolean;
}

const UiPaginator: React.FC<UiPaginatorProps> = ({
  totalPages,
  page = 1,
  onPageChange = () => undefined,
  colorScheme = 'blue',
  size = 'sm',
  isHidden = false,
  ...stackProps
}) => {
  const calculateCurrentPage = useCallback(() => {
    let newPage = page;
    if (page <= 1) {
      newPage = 1;
    } else if (page >= totalPages) {
      newPage = totalPages;
    }

    return newPage;
  }, [page, totalPages]);

  const currentPage = calculateCurrentPage();

  const minValues = useMemo(
    () => Array.from({ length: totalPages <= RULER_SIZE ? totalPages : RULER_SIZE }, (e, i) => i + 1),
    [totalPages]
  );

  const maxValues = useMemo(
    () =>
      Array.from({ length: totalPages <= RULER_SIZE ? totalPages : RULER_SIZE }, (e, i) => totalPages - i).reverse(),
    [totalPages]
  );

  const discoverMid = useCallback(() => {
    if (totalPages <= RULER_SIZE + 2) {
      return Array.from(new Set([...minValues, ...maxValues]));
    }

    if (minValues.includes(currentPage) && currentPage < RULER_SIZE) {
      return minValues;
    }

    if (currentPage >= RULER_SIZE && RULER_SIZE + 2 !== totalPages && !maxValues.includes(currentPage)) {
      return [currentPage - 1, currentPage, currentPage + 1];
    }

    return maxValues;
  }, [currentPage, totalPages, minValues, maxValues]);

  const shouldCompressLeft = useMemo(
    () => totalPages > RULER_SIZE + 2 && currentPage >= RULER_SIZE,
    [totalPages, currentPage]
  );

  const shouldCompressRight = useMemo(
    () => totalPages > RULER_SIZE + 2 && (!maxValues.includes(currentPage) || currentPage <= 4),
    [totalPages, currentPage]
  );

  const midPagesArray: number[] = useMemo(discoverMid, [discoverMid]);

  const handleInputSize = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.style.width = `${e.target.value.toString().length}ch`;
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.valueAsNumber >= 1 && e.target.valueAsNumber <= totalPages) {
        onPageChange(e.target.valueAsNumber);
      } else {
        e.target.valueAsNumber = currentPage;
      }
      handleInputSize(e);
    },
    [onPageChange, currentPage, handleInputSize]
  );

  function getStylesForBtnNumber(page: number): ButtonProps {
    return {
      color: page === currentPage ? 'blue.500' : 'initial',
      borderColor: page === currentPage ? 'blue.500' : 'gray.100',
      colorScheme: page === currentPage ? colorScheme : 'gray',
      fontSize: 14,
      fontWeight: 700
    };
  }

  function getStylesForBtnArrow(pageToActive: number): Partial<IconButtonProps> {
    return {
      variant: 'outline',
      rounded: 'base',
      isDisabled: currentPage === pageToActive,
      bg: currentPage === pageToActive ? 'chakraGray.400' : 'initial',
      color: currentPage === pageToActive ? 'gray.200' : 'gray.400',
      borderColor: currentPage === pageToActive ? 'chakraGray.400' : 'gray.100'
    };
  }

  const ArrowLeftIcon = <Icon as={MdKeyboardArrowLeft} boxSize={8} />;
  const ArrowRightIcon = <Icon as={MdKeyboardArrowRight} boxSize={8} />;

  return (
    <HStack
      my="12"
      alignSelf="flex-end"
      mr={{ base: 'auto', lg: 4 }}
      ml={{ base: 'auto' }}
      visibility={isHidden ? 'hidden' : 'visible'}
      {...stackProps}
    >
      <IconButton
        h="32px"
        w="32px"
        size={size}
        data-testid="@leftArrow"
        aria-label="leftArrow"
        onClick={() => onPageChange(currentPage - 1)}
        icon={ArrowLeftIcon}
        {...getStylesForBtnArrow(1)}
      />

      {shouldCompressLeft && (
        <>
          <Styles.PageBtn size={size} data-testid="@startPage" onClick={() => onPageChange(1)}>
            1
          </Styles.PageBtn>
          <Styles.DotsBtn data-testid="@startDots" size={size} />
        </>
      )}

      {midPagesArray.map((value) => (
        <Styles.NumberBtn
          h="32px"
          w="32px"
          {...getStylesForBtnNumber(value)}
          key={value}
          size={size}
          tabIndex={value === currentPage ? -1 : 0}
          data-testid={`@midPage${value}`}
          onClick={() => {
            onPageChange(value);
          }}
        >
          {value === currentPage ? (
            <Styles.CurrentPage
              data-testid="@pageInput"
              size={size}
              w={`${currentPage.toString().length}ch`}
              onChange={handleInputSize}
              defaultValue={value}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleInputChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
                }
              }}
              onBlur={handleInputChange}
            />
          ) : (
            value
          )}
        </Styles.NumberBtn>
      ))}

      {shouldCompressRight && (
        <>
          <Styles.DotsBtn data-testid="@endDots" size={size} />
          <Styles.PageBtn size={size} data-testid="@endPage" onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </Styles.PageBtn>
        </>
      )}

      <IconButton
        h="32px"
        w="32px"
        size={size}
        data-testid="@rightArrow"
        aria-label="rightArrow"
        onClick={() => onPageChange(currentPage + 1)}
        icon={ArrowRightIcon}
        {...getStylesForBtnArrow(totalPages)}
      />
    </HStack>
  );
};

export default memo(UiPaginator);
