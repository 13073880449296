import { FormControl, FormErrorMessage, InputProps, Icon } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FaCloudUploadAlt } from 'react-icons/fa';
import UiInput from '../UiInput/UiInput';
import * as Styles from './styles';

type Props = {
  label: string;
  fieldError?: FieldError | undefined;
  inputProps?: Omit<InputProps, 'onChange'>;
  dataTestId?: string;
  value?: File | null;
  onChangeFile?: (newFile: File) => void;
};

export default function InputFileForm({ fieldError, inputProps, label, dataTestId, value, onChangeFile }: Props) {
  const [file, setFile] = useState<File | null>();

  useEffect(() => {
    setFile(value);
  }, []);

  useEffect(() => {
    if (onChangeFile && file) {
      onChangeFile(file);
    }
  }, [file]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length || e.target.files.length === 0) {
      setFile(null);
      return;
    }

    setFile(e.target.files[0]);
    e.currentTarget.blur();
  }

  function checkCancelFileExplorer() {
    setTimeout(() => {
      const inputFile = document.querySelector(`.${inputProps?.name}[role=group] input[type=file]`) as HTMLInputElement;
      if (!inputFile.value) {
        setFile(null);
      }
      document.body.onfocus = null;
    }, 500);
  }

  function onClick() {
    document.body.onfocus = checkCancelFileExplorer;
  }

  function showFilenames() {
    if (!file) {
      return '';
    }

    if (file.name.length > 19) {
      return `${file.name.substring(0, 20)}...`;
    }

    return file.name;
  }

  return (
    <FormControl isInvalid={!!fieldError} className={inputProps?.name}>
      <Styles.Label>
        <Styles.Content>
          <Icon as={FaCloudUploadAlt} boxSize="2em" color="blue.600" />
          {file && file.name && file.name.length > 0 ? (
            <Styles.TextFilename>{showFilenames()}</Styles.TextFilename>
          ) : (
            <Styles.Text>{label}</Styles.Text>
          )}
        </Styles.Content>
      </Styles.Label>

      <Styles.InputGroup>
        <UiInput
          {...inputProps}
          type="file"
          data-testid={dataTestId}
          aria-hidden="true"
          onChange={(e) => onChange(e)}
          onClick={() => onClick()}
        />
      </Styles.InputGroup>

      {fieldError && file !== null && <FormErrorMessage>{fieldError?.message}</FormErrorMessage>}
    </FormControl>
  );
}
