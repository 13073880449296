export const keysNdasPtBr = {
  'ndaCompany.view.subtitle': 'Gerencie acordos de confidencialidade de informações',
  'ndaCompany.button.add': 'Enviar',
  'ndaCompany.modal.title': 'Enviar NDA - Acordo de confidencialidade',
  'ndaCompany.modal.subtitle':
    'Para prosseguirmos com sua solicitação, você precisará assinar um acordo de confidencialidade com a Gertec.',
  'ndaCompany.modal.descriptionSteps': 'São 3 passos simples:',
  'ndaCompany.modal.details': 'Detalhes do NDA',
  'ndaCompany.modal.description.project': 'Descrição do projeto',
  'ndaCompany.modal.title1': '1. Descreva brevemente seu projeto:',
  'ndaCompany.modal.title1.placeholder': 'Descreva seu projeto',
  'ndaCompany.modal.download.title': '2. Download',
  'ndaCompany.modal.download.description': 'Você precisa fazer o download do acordo clicando no botão abaixo.',
  'ndaCompany.modal.download.descriptionPartial': 'download do acordo',
  'ndaCompany.modal.download.placeholder': 'Clique aqui para baixar o arquivo',
  'ndaCompany.modal.upload.title': '3. Upload',
  'ndaCompany.modal.upload.description': 'Nos envie de volta o acordo com sua assinatura.',
  'ndaCompany.modal.upload.descriptionPartial': 'acordo com sua assinatura',
  'ndaCompany.modal.upload.placeholder': 'Clique aqui para selecionar o arquivo',
  'ndaCompany.modalSend.success': 'NDA enviado com sucesso.',
  'ndaCompany.modalSend.loadingButton': 'Enviando...',

  'ndaCompany.modalReason.title': 'Justificativa',

  'ndaCompany.table.title': 'Status de NDAs',
  'ndaCompany.table.column.responsible': 'Responsável',
  'ndaCompany.table.column.email': 'Email corporativo',
  'ndaCompany.table.column.requestDate': 'Data de solicitação',
  'ndaCompany.table.column.returnDate': 'Data de retorno',
  'ndaCompany.table.column.status': 'Status',
  'ndaCompany.table.column.actions': 'Ações',

  'ndaCompany.status.0': 'Em análise',
  'ndaCompany.status.1': 'Aprovado',
  'ndaCompany.status.2': 'Reprovado',

  'ndaCompany.table.action.tooltip': 'Ver justificativa',

  'ndaCompany.filter.search.placeholder': 'Buscar responsável',
  'ndaCompany.filter.status.all': 'Todos',

  'ndaGertec.view.subtitle': 'Gerencie acordos de confidencialidade de informações',
  'ndaGertec.button.update': 'Atualizar',

  'ndaGertec.table.company.column.documentId': 'CNPJ/TAXID',
  'ndaGertec.table.company.column.companyName': 'Empresa',
  'ndaGertec.table.company.column.legalRepresentative': 'Responsável',
  'ndaGertec.table.company.column.corporateEmail': 'Email Corporativo',
  'ndaGertec.table.company.column.status': 'Status',
  'ndaGertec.table.company.column.updateDate': 'Data do Status',
  'ndaGertec.table.company.column.userGertec': 'Usuário gertec',
  'ndaGertec.table.company.column.actions': 'Ações',

  'ndaGertec.table.pending.column.documentId': 'CNPJ/TAXID',
  'ndaGertec.table.pending.column.companyName': 'Empresa',
  'ndaGertec.table.pending.column.legalRepresentative': 'Responsável',
  'ndaGertec.table.pending.column.corporateEmail': 'Email Corporativo',
  'ndaGertec.table.pending.column.updateDate': 'Data de Solicitação',
  'ndaGertec.table.pending.column.actions': 'Ações',

  'ndaGertec.table.history.column.document': 'Nome do documento',
  'ndaGertec.table.history.column.version': 'Versão',
  'ndaGertec.table.history.column.updateDate': 'Data de atualização',
  'ndaGertec.table.history.column.changelog': 'Descrição da alteração',
  'ndaGertec.table.history.column.attachment': 'Anexo',

  'ndaGertec.tab.company': 'Empresa',
  'ndaGertec.tab.pending': 'Pendente de aprovação',
  'ndaGertec.tab.history': 'Versão do NDA',

  'ndaGertec.filter.search.placeholder': 'Buscar CNPJ ou empresa',

  'ndaGertec.modal.updateNda.updateNda': 'Atualizar versão do NDA',
  'ndaGertec.modal.updateNda.documentName': 'Nome do documento',
  'ndaGertec.modal.updateNda.placeholderName': 'Informe um nome para o documento',
  'ndaGertec.modal.updateNda.version': 'Versão',
  'ndaGertec.modal.updateNda.updateDate': 'Data de atualização',
  'ndaGertec.modal.discard.title': 'Nova versão de documento',

  'ndaGertec.modal.approval.title': 'Acordo de confidencialidade',
  'ndaGertec.modal.approval.description':
    'A versão do documento anexado foi assinado e está compatível com a versão atualizada do NDA ?',
  'ndaGertec.modal.approval.latestVersion': 'Última versão do NDA',
  'ndaGertec.modal.approval.file.placeholder': 'NDA_document_104.pdf',
  'ndaGertec.modal.approval.loadingButton': 'Registrando...',
  'ndaGertec.modal.approval.approved': 'NDA aprovado com sucesso',
  'ndaGertec.modal.approval.disapproved': 'NDA reprovado',
  'ndaGertec.modal.approval.action.approve': 'Aprovar',
  'ndaGertec.modal.approval.action.reject': 'Reprovar',
  'ndaGertec.modal.approval.action.discard': 'NDA - Detalhes da Empresa',
  'ndaGertec.modal.approval.description.approve': 'Você deseja aprovar este NDA?',
  'ndaGertec.modal.approval.description.reject': 'Você deseja reprovar este NDA?',
  'ndaGertec.modal.approval.description.discard': 'Todas as alterações não salvas serão perdidas.\nContinuar?',
  'ndaGertec.modal.approval.description.project': 'Descrição do projeto',
  'ndaGertec.modal.approval.attached.document': 'Documento anexado',

  'ndaGertec.modal.company.attached.document': 'Documento anexado',
  'ndaGertec.modal.company.last.version.nda': 'Última versão do NDA'
};
