import {
  Center,
  Image,
  StyleProps,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  VStack
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useI18n } from 'src/i18n';
import { GenericTableCell, GenericTableRow } from './interfaces';

interface GenericTableProps extends TableContainerProps {
  heads: GenericTableCell[];
  rows: GenericTableRow[];
  withDynamicHeight?: boolean;
}

const GenericTable = ({ rows, heads, withDynamicHeight = false, ...props }: GenericTableProps) => {
  const { translate } = useI18n();

  const getStyleCell = (th: GenericTableCell): StyleProps => {
    return {
      bg: th.fixIn ? 'white' : undefined,
      display: th.fixIn ? 'block' : undefined,
      position: th.fixIn ? 'sticky' : undefined,
      boxShadow: th.fixIn?.right ? '-1px 1px 1px var(--chakra-colors-blackAlpha-300)' : undefined,
      right: th.fixIn?.right ? 0 : undefined,
      textTransform: props.textTransform || 'capitalize'
    };
  };

  const headsMemo = useMemo(() => {
    return heads.map((th, i) => {
      const children = th.labelComponent ? <th.labelComponent entity={null} /> : th.label;
      const styles = { ...getStyleCell(th), ...th.style };
      return (
        <Th {...styles} key={`${th.label}${i}`}>
          {children}
        </Th>
      );
    });
  }, [heads]);

  const rowsMemo = useMemo(() => {
    return rows.map((entity, i) => {
      const columns = entity.columns.map((cell, i) => {
        const children = cell.labelComponent ? <cell.labelComponent entity={entity.item} /> : cell.label;
        const styles = { ...getStyleCell(cell), ...cell.style };

        return (
          <Td {...styles} key={`${cell.label}${i}`} textTransform="none">
            {children}
          </Td>
        );
      });

      const key = entity.columns[0].label || entity.columns[0].labelComponent?.toString();
      return <Tr key={`${key}${i}`}>{columns}</Tr>;
    });
  }, [rows]);

  const [isHeight700, isHeight1000] = useMediaQuery(['(max-height: 700px)', '(max-height: 1000px)']);

  function getMaxHeight() {
    if (!withDynamicHeight) {
      return 'full';
    }

    if (isHeight700) {
      return '258px';
    }
    if (isHeight1000) {
      return '568px';
    }
    return 'full';
  }

  return (
    <TableContainer {...props} h="full" maxH={getMaxHeight()} overflowY="auto">
      <Table>
        {rowsMemo.length !== 0 && (
          <Thead>
            <Tr>{headsMemo}</Tr>
          </Thead>
        )}
        <Tbody>{rowsMemo}</Tbody>
      </Table>

      {rowsMemo.length === 0 && (
        <Center my={10}>
          <VStack>
            <Image mb={5} src="/no-result-found.svg" />
            <Text>{translate('app.result.notfound')}</Text>
          </VStack>
        </Center>
      )}
    </TableContainer>
  );
};

export default GenericTable;
