import { defineStyleConfig } from '@chakra-ui/react';

export const Spinner = defineStyleConfig({
  baseStyle: {
    borderWidth: '4px',
    borderBottomColor: 'gray.200',
    borderLeftColor: 'gray.200',
    color: 'blue.600'
  },
  defaultProps: {
    size: 'xl'
  }
});
