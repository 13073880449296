export const keysRequestPtBr = {
  'requests.view.subtitle': 'Lista de solicitações abertas pela empresa',

  'requests.filter.status.label': 'Status',
  'requests.filter.status.placeholder': 'Buscar status',
  'requests.filter.type.label': 'Tipo de solicitação',
  'requests.filter.user.placeholder': 'Buscar usuário',
  'requests.filter.type.placeholder': 'Buscar tipo de solicitação',
  'requests.filter.createdBy.me': 'Criadas por mim',
  'requests.filter.createdBy.anyUser': 'Criadas por qualquer usuário',
  'requests.filter.createdBy.participate': 'Das quais eu participo',

  'requests.table.column.type': 'Tipo',
  'requests.table.column.reference': 'Referência',
  'requests.table.column.summary': 'Resumo',
  'requests.table.column.status': 'Status',
  'requests.table.column.serviceProject': 'Projeto de serviço',
  'requests.table.column.requester': 'Solicitante',
  'requests.table.column.creation': 'Criação'
};
