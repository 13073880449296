import { Card, CardHeader } from '@chakra-ui/react';

const WrapperHeaderPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Card variant="unstyled" bg="transparent">
      <CardHeader px={{ base: 0, md: '5' }} pt={4} pb={2}>
        {children}
      </CardHeader>
    </Card>
  );
};

export default WrapperHeaderPage;
