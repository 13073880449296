export const keysClientPtBr = {
  'clients.view.subtitle': 'Lista de empresas cadastradas',

  'clients.section.generalInfo': 'Informações Básicas',
  'clients.section.legalRepresentative': 'Representante',
  'clients.section.address': 'Endereço',

  'clients.field.generalInfo.totalUsers': 'Usuários',
  'clients.field.generalInfo.totalTeams': 'Times',
  'clients.field.generalInfo.documentId': 'CNPJ/TAXID',
  'clients.field.generalInfo.name': 'Razão social',
  'clients.field.generalInfo.segment': 'Segmento',
  'clients.field.generalInfo.domains': 'Domínio',
  'clients.field.generalInfo.created': 'Data de criação',
  'clients.field.status': 'Status',
  'clients.field.generalInfo.active': 'Ativo',
  'clients.field.generalInfo.waiting_complete_registration': 'Aguardando finalizar o cadastro',
  'clients.field.generalInfo.waiting_confirm_registration': 'Aguardando representante legal confirmar cadastro',

  'clients.field.legalRepresentative.name': 'Nome',
  'clients.field.legalRepresentative.email': 'E-mail',
  'clients.field.legalRepresentative.phone': 'Telefone',
  'clients.field.legalRepresentative.active': 'Ativo',
  'clients.field.legalRepresentative.rejected': 'Rejeitado',
  'clients.field.legalRepresentative.waiting_approval': 'Aguardando aprovação',
  'clients.field.legalRepresentative.waiting_confirm_registration': 'Aguardando confirmar cadastro',

  'clients.field.address.zipCode': 'CEP',
  'clients.field.address.addressLine1': 'Logradouro',
  'clients.field.address.addressLine2': 'Número',
  'clients.field.address.district': 'Bairro',
  'clients.field.address.city': 'Cidade',
  'clients.field.address.state': 'Estado (UF)',
  'clients.field.address.additionalInformation': 'Complemento (opcional)',
  'clients.field.empty': 'Não informado',
  'clients.request.error': 'Cliente não encontrado'
};
