import { AxiosRequestConfig } from 'axios';
import { appAxios } from '../../axios';
import { IListTeamsParams, ILisAlltTeamsParams, ICreateTeamDTO, IListTeamsDTO, IListAllTeamsDTO } from '../../models';
import { createDeleteRequest, createGetRequest } from '../shared';

export const URLS = {
  list: () => '/organizations',
  listAll: (companyId: number) => `/companies/${companyId}/organizations`,
  create: '/organizations',
  delete: (id: number) => `/organizations/${id}`
};

export const list = (data: IListTeamsParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListTeamsDTO>({ url: URLS.list(), queryParams: data, axiosRequestConfig });
};

export const listAll = (data: ILisAlltTeamsParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListAllTeamsDTO[]>({ url: URLS.listAll(data.companyId), axiosRequestConfig });
};

export const create = (data: ICreateTeamDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = appAxios
    .post<ICreateTeamDTO>(URLS.create, data, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const destroy = (id: number, axiosRequestConfig?: AxiosRequestConfig) => {
  return createDeleteRequest({ url: URLS.delete(id), axiosRequestConfig });
};
