export default class StringUtils {
  /** regex explanation
    /^
    (?=.*\d)              // must contain at least one digit
    (?=.*[a-z])           // must contain at least one lowercase letter
    (?=.*[A-Z])           // must contain at least one capital letter
    (?=.*[!@#$%^&*()_+\-=.<>])         // must contain at least one special character
    [0-9a-zA-Z!@#$%^&*()_+\-=.<>]{6,}  // must contain at least 6 of the characters mentioned
    $/
  */
  static passwordRegexSuitable =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=.<>])[0-9a-zA-Z!@#$%^&*()_+\-=.<>]{6,}$/;

  static isPasswordSuitable(password: string) {
    return StringUtils.passwordRegexSuitable.test(password);
  }

  static isEmailDomainValid(domain: string) {
    const domainEmailRegexValid =
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

    return domainEmailRegexValid.test(domain);
  }

  static maskCnpj(cnpj: string) {
    if (!cnpj) {
      return '';
    }

    const maxLength = 18;

    return cnpj
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})\.(\d{3})(\d)/, '$1.$2/$3')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .slice(0, maxLength);
  }

  static maskTelephone(phone: string) {
    if (!phone) {
      return '';
    }

    const maxLength = 15;

    return phone
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d+)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})[-](\d)(\d{4})/, '$1$2-$3')
      .slice(0, maxLength);
  }
}
