import { AxiosRequestConfig } from 'axios';
import { IMigrateCompaniesParams, IMigrateItemCompanyDTO } from '../../models';
import { createPostRequest } from '../shared';

export const URLS = {
  list: () => '/gertec/import-company'
};

export const migrateCompanies = (data: IMigrateCompaniesParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createPostRequest<void | IMigrateItemCompanyDTO[]>({ url: URLS.list(), body: data, axiosRequestConfig });
};
