import { useNavigate } from 'react-router-dom';
import { useToastVariants } from 'src/shared/hooks';
import { useI18n } from 'src/i18n';
import { useUserAuth } from '../../../../hooks';
import { useAuthTokenInterceptor, useForbiddenInterceptor, useUnauthorizedInterceptor } from '../../hooks';

const AxiosInterceptorProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { translate } = useI18n();
  const { toastError, toast } = useToastVariants();

  const redirect = () => {
    const toastId = 'forbiddenToastId';
    if (!toast.isActive(toastId)) {
      toastError({ title: translate('error.ERROR-USER-1020'), id: toastId });
    }
    navigate('/home');
  };

  const { signOut, user } = useUserAuth();

  // Requests interceptors
  useAuthTokenInterceptor({
    userToken: user?.auth.token,
    userJiraToken: user?.auth.jiraToken
  });

  // Responses
  useUnauthorizedInterceptor(signOut);
  useForbiddenInterceptor(redirect);

  return <>{children}</>;
};

export default AxiosInterceptorProvider;
