import { APIModels } from 'src/shared/api';

export const createBlankAnswersFromQuestions = (questions: APIModels.ISurveyQuestionDTO[]) => {
  const blankAnswers = questions.map<APIModels.IAnswerQuestionDTO>((question) => {
    return {
      id: question.id,
      answers: []
    };
  });
  return blankAnswers;
};

export const updateAnswers = (
  surveyAnswers: APIModels.IAnswerQuestionDTO[],
  questionId: number,
  alternatives: APIModels.IAnswerAlternativeDTO[]
) => {
  const updatedAnswers = surveyAnswers.map<APIModels.IAnswerQuestionDTO>((question) => {
    if (question.id === questionId) {
      return {
        ...question,
        answers: alternatives
      };
    }
    return question;
  });
  return updatedAnswers;
};
