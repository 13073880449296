// hex - transparency https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
type Colors = {
  [key: string]:
    | {
        main?: string;
        50?: string;
        100?: string;
        150?: string;
        200?: string;
        300?: string;
        400?: string;
        450?: string;
        500?: string;
        600?: string;
        700?: string;
        800?: string;
        900?: string;
        950?: string;
        1000?: string;
      }
    | string;
};

export const colors: Colors = {
  brightYellow: '#fcae27',
  dividerColor: '#0000006B',
  checkboxBorderColor: '#B7B7B7',
  ndaModalTitleColor: '#103F68',
  fileButtonBackgroundColor: '#4299E112',
  blue: {
    300: '#89AAFF',
    400: '#4299E1',
    450: '#929AC1',
    500: '#93AEDE',
    600: '#526D9F',
    700: '#163586',
    800: '#001A45',
    900: '#000C38',
    950: '#001A450A',
    1000: '#001A4505'
  },
  yellow: {
    800: '#FCAF26',
    900: '#EB9A0A'
  },
  red: {
    100: '#FED7D7',
    500: '#E53E3E',
    600: '#B22222'
  },
  green: {
    300: '#C6F6D5',
    800: '#59CD8F',
    900: '#38A169'
  },

  gray: {
    50: '#FCFBFA',
    100: '#E0E0E0',
    150: '#F5F5F5',
    200: '#D9D9D9',
    400: '#B8B2AE',
    500: '#A5A09D',
    900: '#424242',
    950: '#4242427A',
    1000: '#000E26'
  },

  chakraGray: {
    200: '#E2E8F0',
    400: '#A0AEC0'
  }
};
