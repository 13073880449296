import { FormControl, FormControlProps, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { OnChangeValue } from 'chakra-react-select';
import { Controller, ControllerProps, FieldError, FieldValues, Merge } from 'react-hook-form';
// eslint-disable-next-line
import { default as UiSelect } from '../UiSelect/UiSelect';
import type { UiSelectProps } from '../UiSelect/UiSelect';

export type SelectFormOption = {
  label: string;
  value?: string | number;
};

export type SelectFormOptionOnlyLabel = Omit<SelectFormOption, 'value'>;

type Props<TFieldValues extends FieldValues, TSelectOption, TIsMulti extends boolean> = {
  label: string;
  className?: string;
  fieldError: Merge<FieldError, FieldError | undefined[]> | undefined;
  controlProps: Omit<ControllerProps<TFieldValues>, 'render'>;
  selectProps: UiSelectProps<TSelectOption, TIsMulti>;
  onChange?: (option: TSelectOption) => any;
  onChangeMultiple?: (option: TSelectOption[]) => any;
  dataTestId?: string;
  formControlProps?: FormControlProps;
};

export function SelectForm<TFieldValues extends FieldValues, TSelectOption, TIsMulti extends boolean>({
  fieldError,
  label,
  className,
  controlProps,
  selectProps,
  onChange,
  onChangeMultiple,
  dataTestId,
  formControlProps
}: Props<TFieldValues, TSelectOption, TIsMulti>) {
  function onChangeSelect(values: OnChangeValue<TSelectOption, TIsMulti>, onChangeRender: (...event: any[]) => void) {
    if (values && 'length' in (values as object)) {
      if (!onChangeMultiple) {
        throw new Error('onChangeMultiple undefined');
      }

      onChangeRender(onChangeMultiple(values as TSelectOption[]));
      return;
    }

    if (!onChange) {
      throw new Error('onChange undefined');
    }

    onChangeRender(onChange(values as TSelectOption));
  }

  return (
    <FormControl isInvalid={!!fieldError} className={className} {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <Controller
        {...controlProps}
        render={({ field: { onChange: onChangeRender, ref } }) => (
          <UiSelect
            {...selectProps}
            id={controlProps.name}
            instanceId={controlProps.name}
            ref={ref}
            data-testid={dataTestId}
            onChange={(values) => onChangeSelect(values, onChangeRender)}
            chakraStyles={{
              menu: (provided) => ({ ...provided, zIndex: 3 })
            }}
          />
        )}
      />

      {fieldError && <FormErrorMessage>{fieldError?.message}</FormErrorMessage>}
    </FormControl>
  );
}
