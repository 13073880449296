import { AxiosRequestConfig } from 'axios';
import {
  ICreateStaffDto,
  IListStaffRequest,
  IListStaffResponse,
  IStaff,
  IUpdateStaffDto
} from '../../models/dtos/StaffDto';
import { createGetRequest, createPostRequest, requestPut, createDeleteRequest } from '../shared';

const URLS = {
  getOne: (id: number) => `gertec/${id}/users`,
  create: () => `/gertec/users`,
  update: (id: number) => `gertec/${id}/users`,
  list: () => `/gertec/users`,
  deleteOne: (id: number) => `gertec/${id}/users`
};

export const getOne = (id: number, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IStaff>({ url: URLS.getOne(id), axiosRequestConfig });
};

export const create = (data: ICreateStaffDto, axiosRequestConfig?: AxiosRequestConfig) => {
  return createPostRequest<void>({ url: URLS.create(), body: data, axiosRequestConfig });
};

export const update = (data: IUpdateStaffDto, axiosRequestConfig?: AxiosRequestConfig) => {
  const { id, ...rest } = data;
  const aborter = new AbortController();
  const response = requestPut(URLS.update(id), rest, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const list = (data: IListStaffRequest, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListStaffResponse>({
    url: URLS.list(),
    queryParams: {
      ...data,
      status: data.status,
      type: data.type
    },
    axiosRequestConfig
  });
};

export const destroy = (id: number, axiosRequestConfig?: AxiosRequestConfig) => {
  return createDeleteRequest({ url: URLS.deleteOne(id), axiosRequestConfig });
};
