import React, { useEffect } from 'react';
import { Box, GridItem, Checkbox, CheckboxGroup, Grid, Flex } from '@chakra-ui/react';
import { useI18n } from 'src/i18n';
import { UiInput } from 'src/shared/components';
import { TypeAlternative, IAnswerAlternativeDTO, ISurveyAlternativeDTO } from 'src/shared/api/models';
import { SurveyAlternativesProps } from '../interfaces';

export default function SurveyCheckbox({ alternatives, selected, onChange }: SurveyAlternativesProps) {
  const [answers, setAnswers] = React.useState<IAnswerAlternativeDTO[]>([]);
  const { translate } = useI18n();
  const alternativeCommon = alternatives.filter((t) => t.type === TypeAlternative.common);
  const alternativeCustom = alternatives.filter((t) => t.type === TypeAlternative.custom);

  useEffect(() => {
    setAnswers(selected);
  }, [alternatives]);

  useEffect(() => {
    onChange(answers);
  }, [answers]);

  const updateCheckboxArray = (checkboxIdsArray: any) => {
    if (Array.isArray(checkboxIdsArray)) {
      const newArray = checkboxIdsArray.map((elementId) => {
        const checkboxId = parseInt(elementId, 10);
        const customCheckObj = answers.find((answer) => answer.customValue && answer.customValue.length > 0);

        if (customCheckObj?.id === checkboxId) {
          return {
            id: checkboxId,
            customValue: customCheckObj.customValue
          };
        }
        return {
          id: checkboxId
        };
      });
      setAnswers(newArray);
    }
  };

  const getInputValue = (answers: IAnswerAlternativeDTO[], alternative: ISurveyAlternativeDTO) => {
    const customCheckboxIndex = answers.findIndex((answer) => answer.id === alternative.id);

    if (customCheckboxIndex >= 0) {
      return answers[customCheckboxIndex]?.customValue;
    }
    return '';
  };

  const updateTextField = (alternativeId: number, textContent: string) => {
    const updatedAnswersValues = answers.map<IAnswerAlternativeDTO>((answer) => {
      if (answer.id === alternativeId) {
        return {
          ...answer,
          customValue: textContent
        };
      }
      return answer;
    });
    setAnswers(updatedAnswersValues);
  };

  return (
    <Box color="blue.900" minW={{ base: '480px', md: '800px' }} px={5}>
      <CheckboxGroup onChange={updateCheckboxArray} value={answers.map((answer) => answer.id.toString())}>
        {alternativeCommon && (
          <Grid mb={6} templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
            {alternativeCommon?.map((a) => {
              return (
                <GridItem key={a.id.toString()} colSpan={1}>
                  <Checkbox value={a.id.toString()}>{a.description}</Checkbox>
                </GridItem>
              );
            })}
          </Grid>
        )}
        {alternativeCustom && (
          <Grid templateColumns={{ base: 'repeat(1, 1fr)' }} gap={6}>
            {alternativeCustom?.map((alternative) => {
              return (
                <GridItem key={alternative.id.toString()} colSpan={1}>
                  <Flex gap={7}>
                    <Checkbox value={alternative.id.toString()}>{alternative.description}</Checkbox>
                    <UiInput
                      onChange={(e) => updateTextField(alternative.id, e.target.value)}
                      value={getInputValue(answers, alternative)}
                      data-testid="@descriptionInput"
                      type="text"
                      placeholder={translate('survey.checkbox.input')}
                      isDisabled={answers.findIndex((answer) => answer.id === alternative.id) === -1}
                    />
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>
        )}
      </CheckboxGroup>
    </Box>
  );
}
