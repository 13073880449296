import * as ReactDOM from 'react-dom/client';
import AppModule from './AppModule/AppModule';
import SharedModule from './shared';
import './shared/css/scroll.css';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <SharedModule>
    <AppModule />
  </SharedModule>
);
