export const keysSurveyEnUs = {
  'survey.modal.toastSuccessTitle': 'Search sent successfully.',
  'survey.modal.toastSuccessDescription':
    'Thanks for answering our survey. If you need to change any information, you can access it in Profile.',
  'survey.modal.start': 'Start',
  'survey.modal.welcomeTitle': 'Welcome to the Portal do Desenvolvedor',
  'survey.modal.welcomeDescription':
    'So that we can direct personalized solutions and add more value to your customers, participate in our survey and help us understand your needs.',
  'survey.checkbox.input': 'Specify'
};
