import { createContext, useEffect, useMemo, useState } from 'react';

interface ITreeNodeContext {
  currentArticleId: string | undefined;
  updateArticleId: (value: string) => void;
  currentSpaceId: string;
  updateSpaceId: (value: string) => void;
}

export const TreeNodeContext = createContext(null) as unknown as React.Context<ITreeNodeContext>;

interface TreeNodeProviderProps {
  children: JSX.Element;
  onClick?: (id: string) => void;
  selectedArticleId?: string;
  selectedSpaceId: string;
}

export const TreeNodeProvider = ({ children, onClick, selectedArticleId, selectedSpaceId }: TreeNodeProviderProps) => {
  const [articleId, setArticleId] = useState<string | undefined>(selectedArticleId ?? undefined);
  const [spaceId, setSpaceId] = useState<string>(selectedSpaceId);

  useEffect(() => {
    if (onClick && articleId) {
      onClick(articleId);
    }
  }, [articleId]);

  useEffect(() => {
    if (selectedArticleId) {
      setArticleId(selectedArticleId);
    }
  }, [selectedArticleId]);

  useEffect(() => {
    if (selectedSpaceId) {
      setSpaceId(selectedSpaceId);
    }
  }, [selectedSpaceId]);

  const store = useMemo(
    () => ({
      currentArticleId: articleId,
      updateArticleId: setArticleId,
      currentSpaceId: spaceId,
      updateSpaceId: setSpaceId
    }),
    [articleId, setArticleId, spaceId, setSpaceId]
  );

  return <TreeNodeContext.Provider value={store}>{children}</TreeNodeContext.Provider>;
};
