import { useCallback, useContext, useEffect, useState } from 'react';
import { UserProviderContext } from 'src/AppModule/providers/UserProvider/UserProvider';
import { API } from 'src/shared/api';

const useUserAuth = () => {
  const { removeUser, setUser, user } = useContext(UserProviderContext);
  const [isSignedIn, setIsSignedIn] = useState(!!user);

  useEffect(() => {
    setIsSignedIn(!!user);
  }, [user]);

  const signOut = useCallback(removeUser, [removeUser]);

  const signIn = useCallback(setUser, [setUser]);

  const refresh = async () => {
    const { response } = API.Users.getLoggedUser();
    const { info } = await response;
    setUser({ ...user!, info });
  };

  return {
    isSignedIn,
    signOut,
    signIn,
    refresh,
    user
  };
};

export default useUserAuth;
