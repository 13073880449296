import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useToast,
  UseToastOptions
} from '@chakra-ui/react';

function AlertAsToast({ toastOptions }: { toastOptions: UseToastOptions & { onClose(): void } }) {
  const toast = useToast();

  return (
    <Alert status={toastOptions.status} variant={toastOptions.variant}>
      <AlertIcon />
      <Box>
        <AlertTitle>{toastOptions.title}</AlertTitle>
        <AlertDescription>{toastOptions.description}</AlertDescription>
      </Box>
      {toastOptions.isClosable && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => toastOptions.id && toast.close(toastOptions.id!)}
        />
      )}
    </Alert>
  );
}

const useToastVariants = () => {
  const toast = useToast();
  function toastSuccess({ title, ...props }: Omit<UseToastOptions, 'status'>) {
    return toast({
      title,
      status: 'success',
      isClosable: false,
      position: 'top-right',
      variant: 'solid',
      render(props) {
        return <AlertAsToast toastOptions={props} />;
      },
      ...props
    });
  }

  function toastError({ title, ...props }: Omit<UseToastOptions, 'status'>) {
    return toast({
      title,
      status: 'error',
      isClosable: false,
      position: 'top-right',
      variant: 'solid',
      ...props
    });
  }

  function toastInfo({ title, ...props }: Omit<UseToastOptions, 'status'>) {
    return toast({
      title,
      status: 'info',
      isClosable: false,
      position: 'top-right',
      variant: 'solid',
      render(props) {
        return <AlertAsToast toastOptions={props} />;
      },
      ...props
    });
  }

  return {
    toastSuccess,
    toastError,
    toast,
    toastInfo
  };
};

export default useToastVariants;
