import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['control']);

const baseStyle = helpers.definePartsStyle({
  control: {
    _checked: {
      color: 'gray.700',
      background: 'whiteAlpha.900',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'gray.700',
      _hover: {
        background: 'white',
        borderColor: 'gray.700'
      }
    },
    _focus: { boxShadow: 'none' },
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.700'
  },
  label: {
    pointerEvents: 'none'
  }
});

export const Radio = helpers.defineMultiStyleConfig({ baseStyle });
