import { Box, Center, CircularProgress, Collapse, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { TreeNodeProps } from '../interfaces';
import { TreeNodeContext } from '../providers/TreeNodeProvider';
import { NodeIcon } from './NodeIcon';
import { useTreeViewQuery } from '../hooks';

const TreeNode = ({ textProps, title, children, id, depth, onRecursiveOpen }: TreeNodeProps) => {
  const { currentArticleId, updateArticleId, currentSpaceId } = useContext(TreeNodeContext);
  const { listContentIndex } = useTreeViewQuery();
  const { data, isLoading, refetch } = listContentIndex(currentSpaceId, id);
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const isCollapse = children;

  const handleClickTreeItem = () => {
    updateArticleId(id);
  };

  const handleClickCollapse = () => {
    if (isCollapse) {
      onToggle();
    }
  };

  const handleOpenCollapse = () => {
    if (isCollapse) {
      onOpen();
    }
  };

  const handleCloseCollapse = () => {
    if (isCollapse) {
      onClose();
    }
  };

  const handleRecursiveOpen = () => {
    if (onRecursiveOpen) {
      onRecursiveOpen();
    }
    handleOpenCollapse();
  };

  useEffect(() => {
    if (currentArticleId === id) {
      handleRecursiveOpen();
      handleCloseCollapse();
    }
  }, [currentArticleId]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [currentArticleId, isOpen]);

  const contentIndex =
    isLoading || !data ? (
      <Center>
        <CircularProgress isIndeterminate />
      </Center>
    ) : (
      <>
        {isOpen &&
          data.map((item: any, index: number) => (
            <TreeNode
              key={index}
              id={item.id}
              title={item.title}
              textProps={textProps}
              depth={depth + 1}
              onRecursiveOpen={handleRecursiveOpen}
            >
              {item.children}
            </TreeNode>
          ))}
      </>
    );

  return (
    <Box>
      <Flex
        alignItems="center"
        h={8}
        _hover={{ bg: 'chakraGray.200', cursor: 'pointer' }}
        bg={currentArticleId === id ? 'chakraGray.200' : 'none'}
      >
        <Flex w={depth * 4} h={8} onClick={isCollapse ? handleClickCollapse : handleClickTreeItem} />
        <Flex alignItems="center" onClick={isCollapse ? handleClickCollapse : handleClickTreeItem} h="full">
          <IconButton
            variant="none"
            color={textProps?.color}
            aria-label={title}
            h={4}
            w={4}
            icon={<NodeIcon hasChildren={isCollapse} isOpen={isOpen} />}
          />
        </Flex>
        <Flex w="full" overflow="hidden" onClick={handleClickTreeItem}>
          <Text
            {...textProps}
            fontWeight={isCollapse ? '600' : '400'}
            lineHeight={8}
            textOverflow="ellipsis"
            overflow="hidden"
            maxW="full"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
        </Flex>
      </Flex>

      <Collapse in={isOpen}>
        <Box>{contentIndex}</Box>
      </Collapse>
    </Box>
  );
};

export default TreeNode;
