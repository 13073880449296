import { AxiosRequestConfig } from 'axios';
import { IListClientParams, IListClientsDTO } from '../../models';
import { createGetRequest } from '../shared';

export const URLS = {
  list: () => '/gertec/companies'
};

export const list = (data: IListClientParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListClientsDTO>({ url: URLS.list(), queryParams: data, axiosRequestConfig });
};
