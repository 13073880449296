import { AxiosRequestConfig } from 'axios';
import { ICompanyDetailsDTO, ICompanyProfileDTO, ICompanyUpdateProfileDTO } from '../../models/dtos/ICompanyDTO';
import { createGetRequest, createPostRequest, requestPut } from '../shared';

export const URLS = {
  companyDetails: (id: number) => `/gertec/companies/${id}`,
  myProfile: () => '/companies/my-profile',
  resendEmailConfirm: () => '/users/resend-email-confirmation'
};

export const getCompanyDetails = (companyId: number, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ICompanyDetailsDTO>({
    url: URLS.companyDetails(companyId),
    queryParams: {},
    axiosRequestConfig
  });
};

export const getProfileData = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ICompanyProfileDTO>({
    url: URLS.myProfile(),
    queryParams: {},
    axiosRequestConfig
  });
};

export const updateProfileData = (data: ICompanyUpdateProfileDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const formData = new FormData();
  formData.append('legalResponsible', data.legalResponsible);
  formData.append('phone', data.phone);
  formData.append('zipCode', data.zipCode);
  formData.append('street', data.street);
  formData.append('district', data.district);
  formData.append('number', data.number);
  formData.append('city', data.city);
  formData.append('state', data.state);
  formData.append('additionalInformation', data.additionalInformation || '');
  data.domains.forEach((d) => {
    formData.append('domains', d);
  });
  if (data.nda && data.idNda) {
    formData.append('idNda', data.idNda.toString());
    formData.append('nda', data.nda);
    formData.append('description', data.description);
  }
  data.projectsAccess.forEach((d) => {
    formData.append('projectsAccess', String(d));
  });
  formData.append('acceptedReceiveEmail', String(data.acceptedReceiveEmail));

  const response = requestPut(URLS.myProfile(), formData, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const resendEmailConfirm = (body: { email: string }) => {
  return createPostRequest({ url: URLS.resendEmailConfirm(), body });
};
