import { Flex as $Flex, FlexProps } from '@chakra-ui/react';
import styled from 'styled-components';

const Flex = styled($Flex).attrs<FlexProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: { base: 'auto' },
  align: { base: 'left', sm: 'left', md: 'center', lg: 'center', xl: 'center' },
  justifyContent: 'center',
  gap: 'inherit',
  cursor: 'pointer',
  color: 'blue.900',
  role: 'group',
  transitionDuration: '.2s',
  fontSize: 'xs'
}))``;

export default {
  Flex
};
