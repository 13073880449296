export const keysRequestEnUs = {
  'requests.view.subtitle': 'List of requests opened by the company',

  'requests.filter.status.label': 'Status',
  'requests.filter.status.placeholder': 'Search status',
  'requests.filter.type.label': 'Request type',
  'requests.filter.user.placeholder': 'Search user',
  'requests.filter.type.placeholder': 'Seach request type',
  'requests.filter.createdBy.me': 'Created by me',
  'requests.filter.createdBy.anyUser': 'Created by anyone',
  'requests.filter.createdBy.participate': 'From which I am part of',

  'requests.table.column.type': 'Type',
  'requests.table.column.reference': 'Reference',
  'requests.table.column.summary': 'Summary',
  'requests.table.column.status': 'Status',
  'requests.table.column.serviceProject': 'Service project',
  'requests.table.column.requester': 'Requester',
  'requests.table.column.creation': 'Creation'
};
