import { defineStyleConfig } from '@chakra-ui/react';

export const Progress = defineStyleConfig({
  baseStyle: {
    filledTrack: {
      bg: 'blue.700'
    },
    track: {
      borderRadius: 'md'
    }
  }
});
