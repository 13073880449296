import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { Controller, FieldValues } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/controller';

type Props<T extends FieldValues> = {
  children?: React.ReactNode;
  checkbox?: CheckboxProps;
  control: Omit<ControllerProps<T>, 'render'>;
  dataTestId?: string;
};

function CheckboxForm<T extends FieldValues>({ children, control, checkbox, dataTestId }: Props<T>) {
  return (
    <Controller
      {...control}
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox
          {...checkbox}
          color="gray.900"
          fontSize="14px"
          lineHeight="18px"
          fontWeight="600"
          onChange={(e) => {
            onChange(e);
            if (checkbox?.onChange) {
              checkbox.onChange(e);
            }
          }}
          ref={ref}
          isChecked={value}
          data-testid={dataTestId}
        >
          {children}
        </Checkbox>
      )}
    />
  );
}

export default CheckboxForm;
