import { FormControlProps, InputGroupProps, InputProps } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import InputForm from '../InputForm/InputForm';

interface ISearchInputProps {
  inputGroupProps?: InputGroupProps;
  inputProps?: InputProps;
  inputFormControlProps?: FormControlProps;
}

const SearchInput = ({ inputGroupProps, inputProps, inputFormControlProps }: ISearchInputProps) => {
  return (
    <InputForm
      formControlProps={{
        w: { base: 'full', sm: 'full', md: '2xs', lg: '2xs', xl: '2xs' },
        h: '40px',
        ...inputFormControlProps
      }}
      inputGroupProps={{
        ...inputGroupProps,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray.200',
        borderRadius: 4
      }}
      inputProps={{
        ...inputProps,
        value: inputProps?.value || '',
        border: 'none'
      }}
      inputRightElementProps={{
        children: <Search2Icon color="black" />
      }}
      isDisabled={inputProps?.isDisabled}
    />
  );
};

export default SearchInput;
