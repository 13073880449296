import { Box } from '@chakra-ui/react';
import WrapperPage from '../WrapperPage/WrapperPage';

export default function WrapperPageWithScroll({ children }: { children: React.ReactNode }) {
  return (
    <Box overflow="auto" maxH="full">
      <WrapperPage>{children}</WrapperPage>
    </Box>
  );
}
