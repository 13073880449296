enum FileTypes {
  pdf = 'application/pdf',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv = 'text/csv',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export default class FileUtils {
  static Types = FileTypes;

  static isNdaEligble(fileType: string) {
    if (fileType !== FileTypes.doc && fileType !== FileTypes.docx && fileType !== FileTypes.pdf) {
      return false;
    }
    return true;
  }

  static getNdaSupportedExtensions() {
    return `${FileTypes.docx},${FileTypes.doc},${FileTypes.pdf}`;
  }
}
