import * as yup from 'yup';
import { useI18n } from 'src/i18n';

function useYupCommonValidations() {
  const { translate } = useI18n();
  const requiredFieldLabel = translate('app.form.validation.required');
  const requiredNumber = translate('form.required.number');
  const invalidDomain = translate('form.invalid.domain');
  const invalidTelephone = translate('form.invalid.telephone');
  const invalidRole = translate('form.invalid.role');

  yup.setLocale({
    string: {
      max: translate('form.invalid.maxLength').replace('{{', '${').replace('}}', '}')
    }
  });

  const lengthTelephone = [10, 11];
  const validationTelephone = yup
    .string()
    .test(invalidTelephone, invalidTelephone, (value) =>
      lengthTelephone.includes(value?.replace(/\D/g, '').length || 0)
    )
    .required(requiredNumber);

  const validationStringRequired = yup.string().required(requiredFieldLabel);

  const validationArrayDomains = yup
    .mixed()
    .required(requiredFieldLabel)
    .test(invalidDomain, invalidDomain, (value: { value: string }[]) => {
      if (!value) {
        return false;
      }
      if (value.length === 0 || !value.length) {
        return false;
      }

      const itemsHasError = value.filter((element) => {
        const regex =
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

        return !regex.test(element.value);
      });
      return itemsHasError.length === 0;
    });

  const validationTeamsRequired = yup.array().min(0, requiredFieldLabel);

  const validationRoleRequired = yup
    .mixed()
    .required(requiredFieldLabel)
    .test(invalidRole, invalidRole, (option: { value: string }) => {
      if (!option) {
        return false;
      }
      if (option.value.length === 0) {
        return false;
      }
      return true;
    });

  const validationNumberRequired = yup.number().required(requiredNumber);
  const validateCep = yup.string().matches(/^\d{5}-?\d{3}$/, translate('form.invalid.cep'));

  return {
    validateCep,
    validationTelephone,
    validationStringRequired,
    validationArrayDomains,
    validationNumberRequired,
    validationTeamsRequired,
    validationRoleRequired
  };
}

export default useYupCommonValidations;
