import { Heading, HeadingProps } from '@chakra-ui/react';

export type FormTitleProps = {
  textContent: React.ReactNode;
  headingProps?: HeadingProps;
};

export default function FormTitle({ textContent, headingProps }: FormTitleProps) {
  return (
    <Heading {...headingProps} fontSize={20}>
      {textContent}
    </Heading>
  );
}
