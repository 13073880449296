import { FlexProps, Box, Icon, Flex } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { NavLink } from 'react-router-dom';
import { IconNoirType } from 'src/shared/types/icons';

export interface NavItemProps extends FlexProps {
  icon: IconType | IconNoirType;
  url: string;
  isSelected?: boolean;
  children: React.ReactNode;
}
const NavItem: React.FC<NavItemProps> = ({ isSelected, icon, children, url, ...rest }) => (
  <NavLink to={url}>
    <Flex
      {...rest}
      role="group"
      backgroundColor={isSelected ? '#EDEDED' : ''}
      w={{ base: 'auto', sm: 'auto', md: '4.6rem', lg: '4.6rem' }}
      gap={2}
      display={{ base: 'flex', sm: 'flex-start', md: 'block', lg: 'block', xl: 'block' }}
      alignItems="center"
      py={{ base: 0, md: 2 }}
    >
      <Icon
        as={icon}
        color="blue.800"
        boxSize={4}
        w="inherit"
        transition="color.25s"
        _groupHover={{
          color: 'blue.800'
        }}
      />
      <Box lineHeight={4} fontSize="sm">
        {children}
      </Box>
    </Flex>
  </NavLink>
);

export default NavItem;
