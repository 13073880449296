import { Box, Center, CircularProgress, List } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToastVariants } from 'src/shared/hooks';
import { useI18n } from 'src/i18n';
import TreeNode from './components/TreeNode';
import { TreeViewProps } from './interfaces';
import { TreeNodeProvider } from './providers/TreeNodeProvider';
import { useTreeViewQuery } from './hooks';

const TreeView = ({ boxProps, textProps, selectedArticleId, selectedSpaceId, onClick }: TreeViewProps) => {
  const { listContentIndex } = useTreeViewQuery();
  const { data, isLoading, error } = listContentIndex(selectedSpaceId);
  const { toastError } = useToastVariants();
  const { translate } = useI18n();

  const navigate = useNavigate();

  if (error) {
    navigate('/documents');
    toastError({
      id: 'forbidden-document',
      title: translate('document.error.forbidden.title'),
      description: translate('document.error.forbidden.description'),
      duration: 10000,
      isClosable: true
    });
  }

  if (isLoading || !data) {
    return (
      <Box {...boxProps}>
        <Center>
          <CircularProgress isIndeterminate />
        </Center>
      </Box>
    );
  }

  return (
    <TreeNodeProvider onClick={onClick} selectedArticleId={selectedArticleId} selectedSpaceId={selectedSpaceId}>
      <Box {...boxProps}>
        <List>
          {data.map((item: any) => (
            <TreeNode key={item.id} id={item.id} title={item.title} textProps={textProps} depth={0}>
              {item.children}
            </TreeNode>
          ))}
        </List>
      </Box>
    </TreeNodeProvider>
  );
};

export default TreeView;
