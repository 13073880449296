import { AxiosRequestConfig } from 'axios';
import { IDomainDTO } from '../../models';
import { createGetRequest } from '../shared';

export const URLS = {
  list: () => '/domains'
};

export const list = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IDomainDTO[]>({ url: URLS.list(), queryParams: {}, axiosRequestConfig });
};
