import { AxiosRequestConfig } from 'axios';
import { appAxios } from '../../axios';
import { ICreateDeviceDTO, IListDevicesDTO, IListDevicesParams } from '../../models';
import { createDeleteRequest, createGetRequest } from '../shared';

export const URLS = {
  list: '/device',
  destroy: '/gertec/device',
  create: '/gertec/device'
};

export const list = (data: IListDevicesParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListDevicesDTO>({ url: URLS.list, queryParams: data, axiosRequestConfig });
};

export const destroy = (id: number) => {
  return createDeleteRequest<any>({ url: URLS.destroy, axiosRequestConfig: { data: { id } } });
};

export const create = (data: ICreateDeviceDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('description', data.description || '');
  formData.append('storeLink', data.storeLink);
  formData.append('documentationLink', data.documentationLink || '');
  formData.append('supportLink', data.supportLink || '');
  if (data.image) {
    formData.append('image', data.image[0]);
  }
  const response = appAxios
    .post<any>(URLS.create, formData, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response: any) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
};
