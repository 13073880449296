import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Calendar, GetBackForwardPropsOptions } from 'dayzed';
import React from 'react';
import { DatepickerProps } from '../utils/commonTypes';

export interface DatepickerBackBtnsProps extends DatepickerProps {
  calendars: Calendar[];
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

const DefaultBtnStyle: ButtonProps = {
  variant: 'ghost',
  size: 'sm'
};

export const DatepickerBackBtns: React.FC<DatepickerBackBtnsProps> = (props) => {
  const { calendars, getBackProps } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <>
      <Button
        {...getBackProps({
          calendars,
          offset: 12
        })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        <ArrowLeftIcon color="#001A45" />
      </Button>
      <Button {...getBackProps({ calendars })} {...DefaultBtnStyle} {...customBtnProps}>
        <ChevronLeftIcon boxSize={6} color="#001A45" />
      </Button>
    </>
  );
};

export interface DatepickerForwardBtnsProps extends DatepickerProps {
  calendars: Calendar[];
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export const DatepickerForwardBtns: React.FC<DatepickerForwardBtnsProps> = (props) => {
  const { calendars, getForwardProps } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <>
      <Button {...getForwardProps({ calendars })} {...DefaultBtnStyle} {...customBtnProps}>
        <ChevronRightIcon boxSize={6} color="#001A45" />
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12
        })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        <ArrowRightIcon color="#001A45" />
      </Button>
    </>
  );
};
