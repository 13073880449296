import { PropsConfigs } from './external/utils/commonTypes';

export const propsConfig: PropsConfigs = {
  dateNavBtnProps: {
    fontWeight: 800
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      height: '35px',
      width: '35px',
      rounded: '35px',
      borderColor: 'red.300',
      color: 'gray.900',
      _hover: {
        background: 'blue.800',
        color: '#fff'
      }
    },
    isInRangeBtnProps: {
      color: 'gray.900',
      background: 'gray.200'
    },
    selectedBtnProps: {
      background: 'blue.800',
      color: '#fff'
    }
  },
  weekdayLabelProps: {
    color: 'blue.800',
    fontWeight: 'bold'
  },
  dateHeadingProps: {
    color: 'gray.900'
  }
};
