import { Button, TextProps, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const FileButton = styled(Button).attrs({
  variant: 'outline',
  w: '100%',
  mb: 3,
  py: '6',
  height: 'auto',
  fontSize: 'sm',
  borderRadius: 0,
  borderWidth: '1px',
  borderColor: 'gray.900',
  borderStyle: 'dashed',
  _hover: {
    bg: 'gray.50',
    cursor: 'pointer'
  }
})``;

export const FileButtonText = styled(Text).attrs({
  variant: 'outline',
  size: 'lg',
  fontSize: 'sm',
  fontWeight: 'medium',
  color: 'blue.800'
} as TextProps)``;
