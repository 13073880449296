import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['card', 'container', 'body']);

export const Card = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'white'
    }
  },
  sizes: {
    md: {
      body: {
        paddingTop: '20px',
        paddingBottom: '20px'
      }
    }
  },
  defaultProps: {
    size: 'md'
  }
});
