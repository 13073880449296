import { useEffect, useState } from 'react';
import { IAuthUserDTO } from 'src/shared/api/models';
import { AppLocalStorage } from 'src/shared/utils/appLocalStorage/appLocalStorage';
import { PrimeReactProvider } from 'primereact/api';
import { AxiosInterceptorProvider } from './AxiosInterceptorProvider';
import { UserProvider } from './UserProvider/UserProvider';

export * from './AxiosInterceptorProvider';

const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [initialLocalStoreUser, setInitialLocalStoreUser] = useState<IAuthUserDTO>();
  const [loadedUser, setLoadedUser] = useState(false);

  useEffect(() => {
    const stayConnected = AppLocalStorage.getItem('APP:SESSION:STAY_CONNECTED', 'localStorage');
    const typeStorage = stayConnected ? 'localStorage' : 'sessionStorage';
    setInitialLocalStoreUser(AppLocalStorage.getItem('APP:USER', typeStorage));
    setLoadedUser(true);
  }, []);

  if (!loadedUser) {
    return <></>;
  }

  //* Redux state for the app */
  return (
    <UserProvider user={initialLocalStoreUser}>
      {/* // Axios request and response interceptors */}
      <AxiosInterceptorProvider>
        {/* Global menu */}
        {children}
      </AxiosInterceptorProvider>
    </UserProvider>
  );
};

export default AppProviders;
