import { keysClientEnUs } from './keys/enUs/keys-client-en-us';
import { keysDocumentEnUs } from './keys/enUs/keys-document-en-us';
import { keysNdasEnUs } from './keys/enUs/keys-ndas-en-us';
import { keysRequestEnUs } from './keys/enUs/keys-request-en-us';
import { keysServicesEnUs } from './keys/enUs/keys-services-en-us';
import { keysSurveyEnUs } from './keys/enUs/keys-survey-en-us';
import { keysUserEnUs } from './keys/enUs/keys-user-en-us';

export const enUS = {
  'app.month.Jan': 'Jan',
  'app.month.Feb': 'Feb',
  'app.month.Mar': 'Mar',
  'app.month.Apr': 'Apr',
  'app.month.May': 'May',
  'app.month.Jun': 'Jun',
  'app.month.Jul': 'Jul',
  'app.month.Aug': 'Aug',
  'app.month.Sep': 'Sep',
  'app.month.Oct': 'Oct',
  'app.month.Nov': 'Nov',
  'app.month.Dec': 'Dec',
  'app.month.01': 'Jan',
  'app.month.02': 'Feb',
  'app.month.03': 'Mar',
  'app.month.04': 'Apr',
  'app.month.05': 'May',
  'app.month.06': 'Jun',
  'app.month.07': 'Jul',
  'app.month.08': 'Aug',
  'app.month.09': 'Sep',
  'app.month.10': 'Oct',
  'app.month.11': 'Nov',
  'app.month.12': 'Dec',

  'app.label.name': 'Name',
  'app.label.id': 'Id',
  'app.label.accessLevel': 'Access Level',
  'app.label.tags': 'Tags',
  'app.label.lastAccess': 'Last access',
  'app.label.email': 'Email',
  'app.label.username': 'Username',
  'app.label.password': 'Password',
  'app.label.signIn': 'Sign In',
  'app.label.required': 'Required',
  'app.label.minLengthOf': 'Minimum length should be {{number}}',
  'app.label.yes': 'Yes',
  'app.label.no': 'No',
  'app.label.language': 'Language',
  'app.label.theme': 'Theme',
  'app.label.actions': 'Actions',
  'app.label.type': 'Type',
  'app.label.reference': 'Reference',
  'app.label.summary': 'Summary',
  'app.label.version': 'Version',
  'app.label.next': 'Next',
  'app.label.finish': 'Finish',
  'app.label.loadingLink': 'Loading link...',
  'app.label.preposition.to': 'to',
  'app.label.description': 'Description',
  'app.label.storeLink': 'Link to Store',
  'app.label.documentationLink': 'Link to documentation',
  'app.label.supportLink': 'Link to suport',
  'app.label.devicePhoto': 'Image',
  'app.label.devicePhoto.placeholder': 'Select image to device',
  'app.label.storeLink.button': 'Go to Store',
  'app.label.profile': 'Profile',
  'app.label.confirmUpdateChanges': 'Do you really want to save the changes?',

  'app.toast.successfulOperation': 'Operation successful.',

  'app.actions.signOut': 'Sign out',
  'app.actions.edit': 'Edit',
  'app.actions.save': 'Save',
  'app.actions.cancel': 'Cancel',
  'app.actions.send': 'Send',
  'app.actions.sending': 'Sending...',
  'app.actions.delete': 'Delete',
  'app.actions.back': 'Back',
  'app.actions.confirm': 'Confirm',

  'app.result.status': 'Status',
  'app.result.wrongEmailFormat': 'Invalid email format',
  'app.result.notfound': 'Search returned 0 results',
  'app.result.status.2': 'Active',
  'staff.section.title': 'User information',
  'staff.form.name.minLength': 'Minimum length of 2 characters',
  'staff.form.name.maxLength': 'Maximum length 150 characters',
  'staff.create.success': 'User added successfully',
  'staff.update.success': 'User updated successfully',
  'staff.update.error': 'Failed while updating user',
  'staff.create.error': 'Failed while adding user',
  'staff.form.label.status': 'Activate user',
  'staff.table.username': 'Username',
  'staff.table.email': 'E-mail',
  'staff.table.profile': 'Profile',
  'staff.table.status': 'Status',
  'staff.table.actions': 'Actions',
  'staff.table.actions.edit': 'Edit',
  'staff.table.actions.delete': 'Delete',
  'staff.table.search.placeholder': 'Search by user name or user email',
  'staff.button.add': 'User',
  'staff.modal.delete.user': 'Delete user',
  'staff.modal.delete.user.description': 'Do you really want to delete this user? ',
  'staff.modal.delete.user.success': 'User deleted successfully',
  'staff.modal.delete.user.error': 'Failed while deleting user',

  'app.form.validation.required': 'Required field',
  'app.form.validation.emailInvalid': 'Email invalid',
  'app.form.validation.emailMinimalOne': 'Type at least one email',
  'app.form.validation.filetypeInvalid': 'The selected file is not valid',
  'app.form.validation.selectFile': 'Select file',
  'app.form.validation.columnsInvalidSheet': 'Correct the name of the following columns: {{columns}}',

  'app.form.email.placeholder': 'Enter a valid email address',
  'app.form.profile.placeholder': 'Select a profile',

  'app.companies.size': 'Size',
  'app.requests.serviceProject': 'Service Project',
  'app.requests.requester': 'Requester',

  'app.menus.home': 'Home',
  'app.menus.staff': 'Staff',
  'app.menus.staff.header': 'Users list (Gertec)',
  'app.menus.staff.description': 'Manager internal user accounts.',
  'app.menus.staff.create': 'Add user',
  'app.menus.staff.edit': 'Edit user',
  'app.menus.users': 'Users',
  'app.menus.teams': 'Teams',
  'app.menus.devices': 'Devices',
  'app.menus.device': 'Device',
  'app.menus.users.role': 'Role',
  'app.menus.company': 'Profile',
  'app.menus.requests': 'Requests',
  'app.menus.nda-companies': 'NDA',
  'app.menus.nda-gertec': 'NDA',
  'app.menus.migrate': 'Company migration',
  'app.menus.migrateTitle': 'Migration',
  'app.menus.clients': 'Clients',
  'app.menus.dashboard': 'Dashboard',
  'app.navbar.selectNavbarOption': 'Choose content to access',
  'app.navbar.forum': 'Forum',
  'app.navbar.support': 'Support',
  'app.navbar.documentation': 'Documentation',
  'app.navbar.services': 'Services',
  'app.navbar.publishing': 'Publishing',
  'app.navbar.smartstore': 'SmartStore',
  'app.navbar.mymdm': 'My MDM',
  'app.navbar.store': 'Dev Store',

  'app.notifications.forum.registered':
    'You have been added to Gertecs partner and customer community. Enjoy and access right now by clicking on the link',
  'app.notifications.forum.welcome': 'Welcome to the Gertec Forum',
  'app.notifications.formProfileUpdated.title': 'We brought news =)',
  'app.notifications.formProfileUpdated.description':
    'The Profile form has been updated. Please complete your address details.',

  'dashboard.title.welcome': 'Welcome to the',
  'dashboard.title.subtitle': 'Portal do Desenvolvedor',
  'dashboard.title.greeting': 'What would you like to access today ?',
  'dashboard.cards.api.title': 'APIs, SDKs and Support',
  'dashboard.cards.api.description':
    'APIs and SDKs with up-to-date documentation and specialized support to help and clarify doubts',
  'dashboard.cards.forum.title': 'Forum and Communities',
  'dashboard.cards.forum.description': 'Discussion forum available for developers',
  'dashboard.cards.trainings.title': 'Trainings',
  'dashboard.cards.trainings.description': 'Resources to help professionals develop solutions',
  'dashboard.cards.warning.soon': 'SHORTLY',
  'dashboard.image.alt': 'Man sitting using a laptop on a table',
  'dashboard.footer.title': 'Discover our services:',
  'dashboard.footer.card.smartstore.description': 'Gertec App Store',
  'dashboard.footer.card.mymdm.description': 'Terminal monitoring and management platform',

  'errors.ERROR-USER-1002': 'The following emails have already been invited or registered: ',
  'errors.ERROR-USER-1015': 'Unable to create user in Jira',
  'errors.ERROR-USER-1034': 'It is not allowed to delete your own user itself',
  'errors.ERROR-USER-1020': 'You do not have permission',
  'errors.ERROR-OGNZ-1002': 'Unable to associate user in Jira',
  'errors.ERROR-OGNZ-1003': 'Operation stopped, team was registered already',
  'errors.ERROR-CMP-1003': 'Required fields',
  'errors.ERROR-COMMON-1002': 'Exceeded the 255 character limit',
  'errors.ERROR-COMMON-1000': 'Unknown error',
  'errors.ERROR-JIRA-USER-1005': 'Invalid search',
  'errors.ERROR-JIRA-USER-1006': "You don't have permission to access this page",
  'errors.ERROR-MGT-1001': 'Company not found in Jira',
  'errors.ERROR-MGT-1002': 'Company name is required',
  'errors.ERROR-MGT-1003': 'CNPJ is invalid',
  'errors.ERROR-MGT-1004': "Responsible person's name is required",
  'errors.ERROR-MGT-1005': 'Email is invalid',
  'errors.ERROR-MGT-1006': 'Phone is invalid',
  'errors.ERROR-MGT-1007': 'Domain is required',
  'errors.ERROR-MGT-1008': 'CNPJ is duplicated in the sent file',
  'errors.ERROR-MGT-1009': 'Email is duplicated in the sent file',
  'errors.ERROR-MGT-1010': 'This email has already been registered',
  'errors.ERROR-MGT-1011': 'This CNPJ has already been registered',
  'errors.unknownError': 'Unknown error',

  'request.input.search.placeholder': 'The request contains',
  'services.view.publishing.subtitle': 'Publish your apps directly to the terminals',
  'services.view.publishing.paragraph.1':
    'After developing your application, your company will need to publish it on SmartStore or MyMDM',
  'services.view.publishing.paragraph.2': 'Below are the steps necessary to request access for your company!',
  'services.view.publishing.flow.1.title': 'Application Development',
  'services.view.publishing.flow.1.description':
    "When finishing your app development, it's time to make it available for your client",
  'services.view.publishing.flow.2.title': 'Register SmartStore or MyMDM',
  'services.view.publishing.flow.2.requestAccess': 'Request Access',
  'services.view.publishing.flow.2.acceptTerms': 'Accept Terms',
  'services.view.publishing.flow.2.joiningTerms': 'Joining Terms',
  'services.view.publishing.flow.2.signature': 'Contract signatures',
  'services.view.publishing.flow.2.robotAccess': 'Robot Access + QR Code',
  'services.view.publishing.flow.2.robotHowTo': 'Tutorial of how to use Robot',
  'services.view.publishing.flow.3.title': 'Publishing on SmartStore or MyMDM',
  'services.view.publishing.flow.3.description': "All steps done? It's time to publish it on SmartStore or MyMDM.",
  'services.view.publishing.flow.3.howToPublishSmartStore': 'How to publish on SmartStore',
  'services.view.publishing.flow.3.howToPublishMDM': 'How to publish on MyMDM',

  'teams.table.description': 'Description',
  'teams.view.subtitle': 'Manage work teams',
  'teams.button.time': 'Team',
  'teams.header.title': 'Team Register',
  'teams.input.title.placeholder': 'Enter team name here',
  'teams.input.description.placeholder': 'Write a description for your team here',
  'teams.filter.placeholder.searchTeam': 'Search team',
  'teams.modal.delete.title': 'Delete team',
  'teams.modal.delete.message': 'Are you sure you want to delete the team {{team}}?',

  'companies.view.subtitle': 'Manage your data according to your needs',
  'companies.view.info.general.title': 'Company Data',
  'companies.view.info.general.idDocument': 'CNPJ/TAXID',
  'companies.view.info.general.segment': 'Segment',
  'companies.view.info.personal.title': 'Personal Data',
  'companies.view.info.personal.name': 'Name',
  'companies.view.info.personal.email': 'E-mail',
  'companies.view.info.personal.domain': 'Domain',
  'companies.view.info.personal.phone': 'Phone',
  'companies.view.info.personal.phonePlaceholder': '(XX) XXXX-XXXX',
  'companies.view.info.personal.address': 'Address',
  'companies.view.info.personal.zipCode': 'CEP',
  'companies.view.info.personal.zipCodePlaceholder': 'XXXXX-XXX',
  'companies.view.info.personal.search': 'Search',
  'companies.view.info.personal.publicPlace': 'Street',
  'companies.view.info.personal.publicPlacePlaceholder': 'Enter the name of the avenue or street',
  'companies.view.info.personal.number': 'Number',
  'companies.view.info.personal.numberPlaceholder': 'Enter the number',
  'companies.view.info.personal.complements': 'Complements',
  'companies.view.info.personal.complementsPlaceholder': 'House, apartment, room, etc',
  'companies.view.info.personal.optional': 'Optional',
  'companies.view.info.personal.district': 'District',
  'companies.view.info.personal.districtPlaceholder': 'Enter the name of the neighborhood',
  'companies.view.info.personal.state': 'State',
  'companies.view.info.personal.city': 'City',
  'companies.view.section.iWouldLike': 'I Would Like',
  'companies.view.section.ndaRequired': 'Submission of NDA required:',
  'companies.checkbox.title.automationSolutionsFromGertec': 'Gertec automation solutions',
  'companies.checkbox.title.useMyStoreSystemGertecPinPad': 'Using Gertec PIN pad in my local system',
  'companies.checkbox.title.smartPosByGertec': 'Gertec Smart POS',
  'companies.checkbox.title.publishAnApptoMDMorSmartStore': 'Publishing an app to MDM or Smartcare',
  'companies.checkbox.title.acceptNewsletter': 'Receiving e-mails about Gertec opportunities and news',
  'companies.checkbox.title.developOthers': 'Develop for other products',
  'companies.checkbox.title.developPadIntegration': 'Expert support to PIN pad integration',
  'companies.checkbox.title.developPosApplication': 'POS Application development',
  'companies.checkbox.example.automationSolutionsFromGertec': '(ex. Printer, SAT, MFE Integration)',
  'companies.checkbox.example.useMyStoreSystemGertecPinPad': '(ex. Cashier to TEF Integratoin)',
  'companies.checkbox.example.smartPosByGertec': '(ex. ERP to TEF Integration)',
  'companies.checkbox.example.publishAnApptoMDMorSmartStore': '(ex. applications for GPOS700)',
  'companies.checkbox.example.developOthers': 'NDA signature required',
  'companies.checkbox.example.developPadIntegration': 'NDA signature required',
  'companies.checkbox.example.developPosApplication': 'NDA signature required',
  'companies.link.nda.document': 'Send NDA - Non-Disclosure Agreement',
  'companies.button.nda.clickHere': 'Click here',
  'companies.text.nda': 'to access NDA again',
  'companies.screen.disable.title': 'Screen on Update',
  'companies.screen.disable.subtitle': 'Soon will be available.',

  'companies.button.save.loading': 'Saving',
  'components.select.noOptions': 'No options',
  'components.modal.title': 'Update data',
  'components.modal.title.nda': 'Send NDA',
  'components.modal.discard': 'All unsaved data will be lost. Continue anyway?',
  'components.modal.discard.nda': 'All changes will be discarded. Continue?',
  'components.modal.update': 'Are you sure you want to save these changes?',
  'components.modal.reason': 'Reason',
  'components.modal.justifyHere': 'justify here',
  'components.customSelect.clearSelection': 'Clear selection',
  'components.customSelect.xOfy': '{{quantity}} of {{total}}',
  'components.alert.success.save': 'Successfully updated data!',

  'form.required.number': 'Required Number',
  'form.invalid.domain': 'Invalid domain. You need to type something like: your-company.com',
  'form.invalid.telephone': 'Invalid Telephone',
  'form.invalid.role': 'Invalid Role',
  'form.invalid.teams': 'Invalid Teams',
  'form.invalid.cep': 'Invalid ZipCode',
  'form.invalid.cepNotFound': 'Zip code not found. You can manually fill',
  'form.invalid.maxLength': 'Must be less than or equal to {{max}} characters',

  'clients.header.subtitle': 'List of registered companies',
  'clients.table.representative': 'Legal representative',
  'clients.table.register': 'Register',
  'clients.table.telephone': 'Telefone',
  'clients.table.corporateName': 'Corporate name',
  'clients.filter.search.placeholder': 'Search by CNPJ, Corporate Name or Representative',

  'clients.months.sunday': 'S',
  'clients.months.monday': 'M',
  'clients.months.tuesday': 'T',
  'clients.months.wednesday': 'W',
  'clients.months.thursday': 'T',
  'clients.months.friday': 'F',
  'clients.months.saturday': 'S',

  'clients.months.january': 'January',
  'clients.months.february': 'February',
  'clients.months.march': 'March',
  'clients.months.april': 'April',
  'clients.months.may': 'May',
  'clients.months.june': 'June',
  'clients.months.july': 'July',
  'clients.months.august': 'August',
  'clients.months.september': 'September',
  'clients.months.october': 'October',
  'clients.months.november': 'November',
  'clients.months.december': 'December',

  'dashboard.label.callsigns.companies': 'Registered companies',
  'dashboard.label.callsigns.users': 'Registered users',
  'dashboard.label.callsigns.active': 'Active',
  'dashboard.label.callsigns.tooltip.companies.title': 'companies',
  'dashboard.label.callsigns.tooltip.users.title': 'administrators and developers',
  'dashboard.label.callsigns.tooltip': 'Number of users who accessed the portal at least once in the selected period.',
  'dashboard.label.callsigns.visits': 'Unique accesses made (login) in the selected period.',
  'dashboard.label.callsigns.segments.tooltip':
    'Ranking of Segments that have the highest number of registered Companies.',
  'dashboard.label.segments': 'TOP 5 - Segments',
  'dashboard.label.visits': 'Visits',
  'dashboard.label.registeredUsers': 'Registered users',
  'dashboard.label.frequencyAccess': 'Frequency access',
  'dashboard.label.others': 'Others',
  'dashboard.label.previousPeriod': 'Previous period',
  'dashboard.label.currentPeriod': 'Current period',
  'dashboard.date.checkbox.label': 'Compare with the same previous period',
  'dashboard.date.checkbox.tooltip': 'Compare the data with an equivalent period',
  'dashboard.date.select.option1': '24 hours',
  'dashboard.date.select.option2': '7 days',
  'dashboard.date.select.option3': '14 days',
  'dashboard.date.select.option4': '30 days',
  'dashboard.date.select.option5': '6 months',
  'dashboard.date.select.option6': 'Last year',
  'dashboard.chartError': 'Oops! Unable to load data. Try reloading the page',

  'footer.privacyPolicy': 'Privacy Policy',
  'footer.termsConditions': 'Terms Conditions',
  'footer.support': 'Support',
  'footer.copyright': '© {{year}} Portal do Desenvolvedor',
  'footer.gertec': 'Gertec',

  'slide_modal.title': 'Welcome to the Portal do Desenvolvedor',
  'slide_modal.description':
    'So that we can direct personalized solutions and add more value to your customers, participate in our survey and help us understand your needs.',
  'slide_modal.button_start': 'Start',

  ...keysNdasEnUs,
  ...keysRequestEnUs,
  ...keysClientEnUs,
  ...keysSurveyEnUs,
  ...keysUserEnUs,
  ...keysDocumentEnUs,
  ...keysServicesEnUs,

  // intlDefaultFormatter
  intlDateTime: '{{date, datetime}}'
};
