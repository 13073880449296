import { AxiosRequestConfig } from 'axios';
import {
  IContentLabelsDTO,
  IContentPagesByLabelDTO,
  IDownloadImageDTO,
  IDownloadImageQueryParams,
  IGetChildPagesDTO,
  IGetChildPagesParams,
  IPageContentDTO,
  IProjectIndexDTO,
  IProjectIndexParams,
  ISearchContentDTO,
  ISearchContentParams
} from '../../models';
import { IDownloadFileDTO, IDownloadFileQueryParams } from '../../models/dtos/IAtlassianDTO';
import { createGetRequest } from '../shared';

const URLS = {
  contentIndex: (spaceId: string) => `/content-index/${spaceId}`,
  contentPage: () => '/content',
  searchWords: () => '/content/article',
  downloadImage: () => '/content/file-download',
  labels: () => '/content/labels',
  getPagesByLabel: (idLabel: string) => `/content/labels/${idLabel}/pages`,
  getChildPages: (articleId: string) => `/content/${articleId}/adf/child-pages`
};

export const listProjectIndex = (data: IProjectIndexParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IProjectIndexDTO[]>({
    url: URLS.contentIndex(data.spaceId),
    queryParams: { queryPageId: data.articleId ?? undefined },
    axiosRequestConfig
  });
};

export const getPageContent = (idCode: string, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IPageContentDTO>({
    url: URLS.contentPage(),
    queryParams: { idCode },
    axiosRequestConfig
  });
};

export const getPagesByLabel = (idLabel: string, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IContentPagesByLabelDTO>({
    url: URLS.getPagesByLabel(idLabel),
    queryParams: {},
    axiosRequestConfig
  });
};

export const getLabels = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IContentLabelsDTO>({
    url: URLS.labels(),
    queryParams: {},
    axiosRequestConfig
  });
};

export const getChildPages = (
  articleId: string,
  data: IGetChildPagesParams,
  axiosRequestConfig?: AxiosRequestConfig
) => {
  return createGetRequest<IGetChildPagesDTO>({
    url: URLS.getChildPages(articleId),
    queryParams: data,
    axiosRequestConfig
  });
};

export const searchContent = (data: ISearchContentParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ISearchContentDTO>({
    url: URLS.searchWords(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const downloadImage = (data: IDownloadImageQueryParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IDownloadImageDTO>({
    url: URLS.downloadImage(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const downloadFile = (data: IDownloadFileQueryParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IDownloadFileDTO>({
    url: data.link,
    queryParams: {},
    axiosRequestConfig
  });
};
