export const keysServicesPtBr = {
  'services.smartstore.title': 'SmartStore',
  'services.smartstore.titlePartial': 'Store',
  'services.smartstore.subtitle': 'Disponibilize seus aplicativos remotamente para os terminais',
  'services.smartstore.install': 'Instalação e atualização remota no terminal',
  'services.smartstore.installPartial': 'Instalação',
  'services.smartstore.management': 'Gestão simples com cadastro de terminais via PIN/QR Code',
  'services.smartstore.managementPartial': 'Gestão simples',
  'services.smartstore.access': 'Acesso ao robô de assinatura 24x7',
  'services.smartstore.accessPartial1': 'Acesso',
  'services.smartstore.accessPartial2': '24x7',
  'services.smartstore.apps': 'Gerencie app e versões disponíveis para seus usuários',
  'services.smartstore.appsPartial': 'Gerencie app e versões',
  'services.smartstore.cardFree.title': 'Gratuita',
  'services.smartstore.cardFree.subtitle': 'Ideal para começar a validar sua solução',
  'services.smartstore.cardPublic.title': 'Pública',
  'services.smartstore.cardPublic.subtitle': 'Ideal para negócios em crescimento',
  'services.smartstore.cardPrivate.title': 'Privada',
  'services.smartstore.cardPrivate.subtitle': 'Ideal para escalar a solução e atingir mais clientes',
  'services.smartstore.card.devices': 'Devices',
  'services.smartstore.card.post': 'Publicação',
  'services.smartstore.card.posts': 'Publicações',
  'services.smartstore.card.supportSla': 'SLA Atendimento',
  'services.smartstore.card.supportTicket': 'Suporte via ticket',
  'services.smartstore.card.supportTicketPartial': '(Central de Ajuda)',
  'services.smartstore.card.supportChatPhone': 'Suporte via Telefone/Chat',
  'services.smartstore.card.button': 'Solicitar',
  'services.smartstore.card.update': 'Atualização Automática',

  'services.mymdm.title': 'MyMDM',
  'services.mymdm.titlePartial': 'MDM',
  'services.mymdm.subtitle': 'Plataforma de monitoramento e gestão de terminais',
  'services.mymdm.monitoring': 'Monitoramento remoto dos terminais em tempo real através de diversos dashboards',
  'services.mymdm.monitoringPartial': 'Monitoramento',
  'services.mymdm.settings':
    'Configuração remota e a opção de habilitar ou desabilitar as funções de configuração do terminal',
  'services.mymdm.settingsPartial': 'Configuração remota',
  'services.mymdm.custom': 'Personalização remota do launcher e opções de acesso aos aplicativos',
  'services.mymdm.customPartial': 'Personalização remota do launcher',
  'services.mymdm.install': 'Instalação e atualização remota dos aplicativos',
  'services.mymdm.installPartial': 'Instalação e atualização remota',
  'services.mymdm.support': 'Suporte Remoto disponível por dispositivo e usuário',
  'services.mymdm.supportPartial': 'Suporte Remoto',
  'services.mymdm.panel': 'Crie painéis e relatórios customizados para análise de dados conforme seu interesse ',
  'services.mymdm.panelPartial': 'Crie painéis e relatórios customizados',
  'services.mymdm.cost': 'Reduza o custo operacional com instalações, atendimento e incidentes de suporte',
  'services.mymdm.costPartial': 'Reduza o custo',
  'services.mymdm.increase':
    'Aumente a disponibilidade de uso e garantir a alta performance do terminal através do monitoramento preventivo e de atualizações programadas',
  'services.mymdm.increasePartial': 'Aumente',

  'services.mymdm.footerDescription':
    'O MyMDM é uma ferramenta completa que possibilita ao cliente ou parceiro a gestão remota da sua base de terminais, incluindo os recursos de monitoramento e configuração dos terminais e a distribuição ou atualização dos aplicativos pelo próprio MyMDM ou por meio da Smart Store.',
  'services.mymdm.footerDescriptionPartial1': 'MyMDM',
  'services.mymdm.footerDescriptionPartial2': 'Smart Store',
  'services.mymdm.footerActionButton': 'Tenho interesse'
};
