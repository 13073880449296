import { Icon, Tooltip, Text, HStack } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdOutlineThumbUp, MdOutlineThumbDown, MdAccessTime } from 'react-icons/md';
import { StatusNda } from 'src/shared/api/models';
import { useI18n } from 'src/i18n';

interface Props {
  statusNda: StatusNda;
  shouldShowText?: boolean;
}

const NdaStatusIcon = ({ statusNda, shouldShowText }: Props) => {
  const { translate } = useI18n();
  let componentIcon: IconType;
  let colorText: string;

  if (statusNda === StatusNda.approved) {
    componentIcon = MdOutlineThumbUp;
    colorText = 'green.500';
  } else if (statusNda === StatusNda.disapproved) {
    componentIcon = MdOutlineThumbDown;
    colorText = 'red.500';
  } else {
    componentIcon = MdAccessTime;
    colorText = 'gray.500';
  }

  return (
    <Tooltip label={translate(`ndaCompany.status.${statusNda}`)} placement="auto">
      <HStack spacing={2}>
        <Icon boxSize={6} as={componentIcon} color={colorText} />(
        {shouldShowText && (
          <Text fontWeight="bold" color={colorText}>
            {translate(`ndaCompany.status.${statusNda}`)}
          </Text>
        )}
        )
      </HStack>
    </Tooltip>
  );
};

export default NdaStatusIcon;
