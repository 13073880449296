import { Navigate } from 'react-router-dom';
import { LinkItemProps } from 'src/AppModule/components/WithNav/LinkItems';
import { useUserAuth } from '../../../hooks';

export interface AuthGuardProps {
  component: React.ReactElement;
  linkConfig: LinkItemProps;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ component, linkConfig }) => {
  const redirect = () => <Navigate to="/" replace />;

  const { user } = useUserAuth();
  if (!user) {
    return redirect();
  }

  if (linkConfig.allowedUserTypes.includes(user.type) && linkConfig.canAccess(user)) {
    return component;
  }

  return redirect();
};

export default AuthGuard;
