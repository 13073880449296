import { AxiosRequestConfig } from 'axios';
import { IDashboardParams, IIndicativesDTO, ITopSegmentDTO, IVisitsDTO } from '../../models';
import { createGetRequest } from '../shared';

export const URLS = {
  getTopSegments: () => 'dashboards-data/top-segments',
  getIndicatives: () => 'dashboards-data/indicatives',
  getVisits: () => 'dashboards-data/visits'
};

export const getTopSegments = (data: IDashboardParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ITopSegmentDTO[]>({
    url: URLS.getTopSegments(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const getIndicatives = (data: IDashboardParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IIndicativesDTO>({
    url: URLS.getIndicatives(),
    queryParams: data,
    axiosRequestConfig
  });
};

export const getVisits = (data: IDashboardParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IVisitsDTO>({
    url: URLS.getVisits(),
    queryParams: data,
    axiosRequestConfig
  });
};
