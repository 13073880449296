import React from 'react';
import { FormControl, FormControlProps, FormErrorMessage, FormLabel, FormLabelProps } from '@chakra-ui/react';
import { Controller, ControllerProps, FieldError, FieldValues, Merge } from 'react-hook-form';
import { SelectFormOption, UiSelect } from 'src/shared/components';

type Props<TFieldValues extends FieldValues> = {
  label: string;
  placeholder: string;
  className?: string;
  fieldError: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  controlProps: Omit<ControllerProps<TFieldValues>, 'render'>;
  dataTestId?: string;
  formControlProps?: FormControlProps;
  options: SelectFormOption[];
  isDisabled?: boolean;
  variant?: 'none';
  formLabelProps?: FormLabelProps;
};

export default function SelectBasicForm<TFieldValues extends FieldValues>({
  fieldError,
  label,
  className,
  controlProps,
  dataTestId,
  formControlProps,
  options,
  placeholder,
  isDisabled,
  variant,
  formLabelProps
}: Props<TFieldValues>) {
  return (
    <FormControl isInvalid={!!fieldError} className={className} {...formControlProps}>
      <FormLabel color="gray.900" {...formLabelProps}>
        {label}
      </FormLabel>
      <Controller
        {...controlProps}
        render={({ field: { onChange: onChangeRender, ref, value } }) => (
          <UiSelect
            isDisabled={isDisabled}
            placeholder={placeholder}
            isSearchable={false}
            value={options.find((c) => c.value === value)}
            options={options}
            onChange={(val) => onChangeRender(val?.value)}
            id={controlProps.name}
            instanceId={controlProps.name}
            ref={ref}
            data-testid={dataTestId}
            chakraStyles={{
              menu: (provided) => ({ ...provided, zIndex: 3 })
            }}
            variant={variant}
          />
        )}
      />

      {fieldError && <FormErrorMessage>{fieldError?.message}</FormErrorMessage>}
    </FormControl>
  );
}
