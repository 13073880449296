export const keysUserPtBr = {
  'users.view.home': 'Início',
  'users.view.subtitle': 'Gerencie pessoas e permissões',
  'users.guard.userId': 'Usuário do id {userId} não pode ser encontrado',
  'users.guard.userId.description': 'Confira o id e tente novamente',

  'users.editUser': 'Editar usuário',
  'user.updatePicture': 'Atualizar imagem',
  'user.removePicture': 'Remover imagem',
  'user.accessLevel.director': 'Diretor',
  'user.accessLevel.manager': 'Gerente',
  'user.accessLevel.contributor': 'Contribuidor',
  'user.birthday': 'Aniversário',

  'users.tab.approved': 'Usuários aprovados',
  'users.tab.pending': 'Pendentes de aprovação',
  'users.tab.rejected': 'Rejeitados',

  'users.table.permission': 'Permissão',
  'users.table.corporateEmail': 'Email corporativo',
  'users.table.defaultoption.selectinput': 'Todos',
  'users.table.actions.approve.tooltip': 'Aprovar',
  'users.table.actions.reject.tooltip': 'Rejeitar',
  'users.table.type': 'Função',
  'users.table.type.0': 'Desenvolvedor',
  'users.table.type.1': 'Administrador',
  'users.table.type.3': 'Proprietário',

  'users.toast.approved': 'Usuário aprovado',
  'users.toast.rejected': 'Usuário rejeitado',

  'users.modal.delete.title': 'Remover usuário',
  'users.modal.delete.username': '<0>Tem certeza que deseja deletar <1>{{username}}</1> ?</0>',

  'users.modal.invite.placeholder': 'Digite os emails aqui',
  'users.modal.invite.guidelines':
    'Para adicionar um email, digite no campo e em seguida clique na opção mostrada OU pressione Enter',
  'users.modal.invite.addMessage': 'Adicionar',
  'users.modal.invite.message': 'Envie convites para pessoas que fazem parte da sua empresa:',
  'users.modal.invite.button': 'Convidar',
  'users.modal.invite.title': 'Convidar pessoas',
  'users.modal.invite.toast.title': 'Enviado!',
  'users.modal.invite.domains': 'Domínios cadastrados:',
  'users.modal.invite.domainInvalid': 'Domínio não cadastrado',
  'users.modal.invite.send': 'Enviar convite',

  'users.modal.reject.title': 'Reprovar Usuário',
  'users.modal.reject.subtitle': 'Justifique abaixo o motivo da reprovação:',
  'users.modal.reject.placeholder': 'Justifique aqui:',
  'users.modal.reject.confirm': 'Confirmar',
  'users.modal.input.validation.maximum': 'A justificativa deve ter no máximo 250 caracteres',
  'users.modal.input.validation.minimum': 'A justificativa deve ter no mínimo 5 caracteres',

  'users.modal.input.name.validation.maximum': 'O nome deve ter no máximo 190 caracteres',
  'users.modal.input.name.placeholder': 'Digite seu nome',
  'users.modal.input.teams.placeholder': 'Informe o time do usuário',
  'users.modal.input.isAdm': 'Usuário é um adminstrador ?',

  'users.modal.update.roles.database_adm': 'Administrador de banco de dados',
  'users.modal.update.roles.network_adm': 'Administrador de redes',
  'users.modal.update.roles.system_adm': 'Administrador de sistemas operacionais',
  'users.modal.update.roles.cybersecurity_adm': 'Administrador em segurança da informação',
  'users.modal.update.roles.software_analyst': 'Analista de desenvolvimento de sistemas',
  'users.modal.update.roles.business_analyst': 'Analista de negócios',
  'users.modal.update.roles.network_analyst': 'Analista de redes e de comunicação de dados',
  'users.modal.update.roles.test_analyst': 'Analista de testes de tecnologia da informação',
  'users.modal.update.roles.software_developer': 'Desenvolvedor de sistemas de tecnologia da informação (técnico)',
  'users.modal.update.roles.web_developer': 'Desenvolvedor web (técnico)',
  'users.modal.update.roles.it_director': 'Diretor de tecnologia da informação',
  'users.modal.update.roles.rd_director': 'Diretor técnico (pesquisa e desenvolvimento)',
  'users.modal.update.roles.computer_engineer': 'Engenheiro de aplicativos em computação',
  'users.modal.update.roles.qa_engineer': 'Engenheiro de controle de qualidade',
  'users.modal.update.roles.hardware_engineer': 'Engenheiro de equipamentos em computação',
  'users.modal.update.roles.network_engineer': 'Engenheiro de redes de comunicação',
  'users.modal.update.roles.software_develop_manager': 'Gerente de desenvolvimento de sistemas',
  'users.modal.update.roles.it_infrastructure_manager': 'Gerente de infraestrutura de tecnologia da informação',
  'users.modal.update.roles.it_project_manager': 'Gerente de projetos de tecnologia da informação',
  'users.modal.update.roles.cybersecurity_manager': 'Gerente de segurança da informação',
  'users.modal.update.roles.it_support_manager': 'Gerente de suporte técnico de tecnologia da informação',
  'users.modal.update.roles.system_operator': 'Operador de sistema de computador',
  'users.modal.update.roles.it_programmer': 'Programador de sistemas de informação',
  'users.modal.update.roles.programmer_technician': 'Técnico analista de programação',
  'users.modal.update.roles.qa_technician': 'Técnico analista de qualidade',
  'users.modal.update.roles.others': 'Outros',
  'users.modal.update.roles.representative': 'Representante Legal'
};
