import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['th']);

export const Table = helpers.defineMultiStyleConfig({
  variants: {
    simple: {
      th: {
        color: 'gray.900',
        borderColor: 'gray.100'
      },
      td: {
        color: 'gray.900',
        borderColor: 'gray.100'
      }
    }
  },
  sizes: {
    lg: {
      th: {
        lineHeight: '4',
        px: '6',
        py: '3'
      },
      td: {
        lineHeight: '5',
        px: '6',
        py: '3',
        fontSize: 'sm'
      }
    }
  },
  defaultProps: {
    size: 'lg'
  }
});
