import { FlexProps, Box, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import Styles from '../NavItem/styles';

export interface NavButtonProps extends FlexProps {
  icon: IconType;
  iconColor: string;
  onClick: () => void;
  children: React.ReactNode;
}
const NavButton: React.FC<NavButtonProps> = ({ icon, iconColor, children, onClick }) => (
  <Styles.Flex role="group" onClick={onClick}>
    <Box borderRadius="4px" py={0.5}>
      <Icon boxSize={4} as={icon} color={iconColor} />
    </Box>
    <Box _groupHover={{ textDecor: 'underline' }} color="blue.800" py={0.5}>
      {children}
    </Box>
  </Styles.Flex>
);

export default NavButton;
