import { Flex as $Flex, FlexProps, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const Flex = styled($Flex).attrs<FlexProps>(
  () =>
    ({
      width: '100%',
      height: '65px',
      pl: 5,
      pr: 5,
      align: 'center',
      cursor: 'pointer',
      color: 'gray.500',
      role: 'group',
      transitionDuration: '.2s',
      transitionTimingFunction: 'ease-in-out',
      transition: 'color .25s',
      _hover: {
        textDecor: 'underline'
      }
    } as FlexProps)
)`
  text-decoration: none;
  &:hover {
    transition: 0.25s;
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

export const TextOption = styled(Text).attrs(() => ({
  fontWeight: 'normal',
  color: 'white',
  fontSize: 'md'
}))``;
