import { AxiosRequestConfig } from 'axios';
import { appAxios } from '../../axios';
import {
  ICreateUserDTO,
  IUserDTO,
  IListUsersDTO,
  IInviteEmailDTO,
  IListUsersParams,
  ILoggedUserDTO,
  IUpdateUserDTO,
  IRoleResponse
} from '../../models';
import { createGetRequest, requestPost, requestPut } from '../shared';

// DELETE ME
import { FAKE_DATA as AccessLevelFakeData } from '../accessLevel/accesslevel';
import { FAKE_DATA as TagsFakeData } from '../tags/tags';

export const URLS = {
  list: () => '/users',
  getRoles: () => '/roles',
  getLoggedUser: () => '/users/my-profile',
  approve: (id: number) => `users/${id}/approval`,
  disapprove: (id: number) => `users/${id}/disapproval`,
  updateUserById: (id: number) => `/users/${id}`,
  inviteEmails: 'users/send-invites',
  create: '/mock',
  getById: (_id: any) => `/mock`,
  update: (_id: any) => `/mock`,
  remove: (_id: any) => `/mock`
};

// DELETE ME
export const FAKE_DATA: Omit<IUserDTO, 'id'>[] = [
  {
    name: 'Gustavo Vieira',
    userName: 'vntguva',
    email: 'gustavo.vieira@venturs.org.br',
    picture: 'https://picsum.photos/200',
    accessLevel: { id: 3, value: 'Contributor' },
    tags: [{ id: 1, value: 'Patamon' }],
    birthday: new Date('1989-5-23').toISOString()
  },
  {
    name: 'Kleber Nardi',
    userName: 'rubinho',
    email: 'kleber.nardi@venturus.org',
    accessLevel: {
      id: 1,
      value: 'Director'
    },
    picture: 'https://picsum.photos/200',
    tags: [{ id: 2, value: 'Angemon' }],
    birthday: new Date('1960-7-20').toISOString()
  },
  {
    name: 'João Maia',
    userName: 'johnMay',
    email: 'joao.maia@venturus.org',
    accessLevel: {
      id: 1,
      value: 'Director'
    },
    picture: 'https://picsum.photos/200',
    tags: [],
    birthday: new Date('1950-7-2').toISOString()
  }
];

// FIX ME
export const getById = (id: IUserDTO['id'], axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = appAxios
    .get<IUserDTO>(URLS.getById(id), {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response: any) => response.data)
    // Remove this
    .then(() => {
      const user = FAKE_DATA.find((e, index) => index + 1 === id);
      if (user) {
        return { ...user, id };
      }
      return Promise.reject(Error('404'));
    })
    .catch(() => Promise.resolve(null));

  return {
    response,
    abort: () => aborter.abort()
  };
};

// FIX ME
export const create = (data: ICreateUserDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key !== 'picture') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });
  const response = appAxios
    .post<ICreateUserDTO>(URLS.create, formData, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response: any) => response.data)

    // Remove this
    .then(() => {
      const user = {
        ...data,
        id: new Date().getTime(),
        accessLevel: {
          id: data.accessLevel,
          value: AccessLevelFakeData.find((e) => e.id === data.accessLevel)?.value || ''
        },
        tags: data.tags.map((tagId) => TagsFakeData.find(({ id }) => id === tagId)!)
      };
      FAKE_DATA.push(user);
      return user;
    });

  return {
    response,
    abort: () => aborter.abort()
  };
};

// FIX ME
export const update = (id: IUserDTO['id'], data: ICreateUserDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key !== 'picture') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });
  const response = appAxios
    .put<ICreateUserDTO>(URLS.update(id), formData, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response: any) => response.data)

    // Remove this
    .then(() => {
      const user = FAKE_DATA.find((e, index) => index + 1 === id);
      if (user) {
        FAKE_DATA[id - 1] = {
          ...data,
          accessLevel: {
            id: data.accessLevel,
            value: AccessLevelFakeData.find(({ id }) => id === data.accessLevel)!.value
          },
          tags: data.tags.map((tagId) => TagsFakeData.find(({ id }) => id === tagId)!)
        };
        return { ...FAKE_DATA[id - 1], id };
      }
      return Promise.reject(Error('404'));
    });

  return {
    response,
    aborter: () => aborter.abort()
  };
};

// FIX ME
export const remove = (id: IUserDTO['id'], axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = appAxios
    .delete<never>(URLS.remove(id), {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    // Remove this
    .then(() => {
      const userIndex = FAKE_DATA.findIndex((e, index) => index + 1 === id);
      if (userIndex > 0) {
        FAKE_DATA.splice(userIndex, 1);
        return Promise.resolve();
      }
      return Promise.reject(Error('404'));
    });

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const inviteEmails = (data: Object, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = appAxios
    .post<IInviteEmailDTO>(URLS.inviteEmails, data, {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const updateUserById = (id: number, data: IUpdateUserDTO, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = requestPut(URLS.updateUserById(id), data, aborter, axiosRequestConfig);

  return {
    response,
    aborter: () => aborter.abort()
  };
};

export const approve = (idUser: number, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = requestPost(URLS.approve(idUser), {}, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const disapprove = (idUser: number, reason: string, axiosRequestConfig?: AxiosRequestConfig) => {
  const aborter = new AbortController();
  const response = requestPost(URLS.disapprove(idUser), { reason }, aborter, axiosRequestConfig);

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const list = (data: IListUsersParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListUsersDTO>({ url: URLS.list(), queryParams: data, axiosRequestConfig });
};

export const getRoles = (status: number, axiosRequestConfig: AxiosRequestConfig = {}) => {
  const aborter = new AbortController();
  const response = appAxios
    .get<IRoleResponse>(URLS.getRoles(), {
      params: { status },
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data)
    .catch(() => Promise.resolve({ role: [] }));

  return {
    response,
    abort: () => aborter.abort()
  };
};

export const getLoggedUser = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<ILoggedUserDTO>({ url: URLS.getLoggedUser(), axiosRequestConfig });
};
