import { Flex, FlexProps } from '@chakra-ui/react';
import styled from 'styled-components';

export const Nav = styled(Flex).attrs({
  h: 'full'
} as FlexProps)`
  @media screen and (min-width: 48em) {
    flex-direction: row;
  }
`;

export default Nav;
