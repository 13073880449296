import axios, { AxiosRequestConfig } from 'axios';

type StateUFProps = {
  id: string;
  sigla: string;
  nome: string;
};

type CitySelectProps = {
  id: string;
  nome: string;
};

export const URLS = {
  getAddressByCep: (cep: string) => `${process.env.REACT_APP_API_CEP}/${cep}/json`,
  getUFbyId: (stateId: string) => `${process.env.REACT_APP_API_ESTADOS}/${stateId}/municipios`,
  listBrazilianStates: () => `${process.env.REACT_APP_API_ESTADOS}`
};

export function getAddressByCep(cep: string, axiosRequestConfig?: AxiosRequestConfig) {
  const aborter = new AbortController();
  const response = axios
    .get<{ logradouro: string; bairro: string; localidade: string; uf: string; erro: unknown }>(
      URLS.getAddressByCep(cep),
      {
        ...axiosRequestConfig,
        signal: axiosRequestConfig?.signal || aborter.signal
      }
    )
    .then((response) => response.data);
  return {
    response,
    abort: () => aborter.abort()
  };
}

export function listCitiesByStateId(stateId: string, axiosRequestConfig?: AxiosRequestConfig) {
  const aborter = new AbortController();
  const response = axios
    .get<CitySelectProps[]>(URLS.getUFbyId(stateId), {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);
  return {
    response,
    abort: () => aborter.abort()
  };
}

export function listBrazilianStates(axiosRequestConfig?: AxiosRequestConfig) {
  const aborter = new AbortController();
  const response = axios
    .get<StateUFProps[]>(URLS.listBrazilianStates(), {
      ...axiosRequestConfig,
      signal: axiosRequestConfig?.signal || aborter.signal
    })
    .then((response) => response.data);
  return {
    response,
    abort: () => aborter.abort()
  };
}
