import React from 'react';
import {
  Modal,
  ModalProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
  Box,
  VStack,
  Highlight,
  useDisclosure,
  Textarea,
  FormControl
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputFileForm, LinkToDownload } from 'src/shared/components';
import { SaveChangesModal } from 'src/modules/CompanyModule/components';
import { useI18n } from 'src/i18n';
import * as yup from 'yup';
import { Utils } from 'src/shared-hold/utils';
import { API } from 'src/shared/api';
import * as Styles from './styles';

const MAX_DESCRIPTION_LENGTH = 300;
interface Props {
  modalProps: Omit<ModalProps, 'children'>;
  onConfirm?: (values: IUpdateProfileForm) => void;
  onCancel?: () => void;
}

type IUpdateProfileForm = {
  nda: FileList;
  description: string;
};

const useFormScheme = () => {
  const { translate } = useI18n();
  const formScheme = yup
    .object({
      nda: yup.mixed().test('invalidFileType', function validate(value) {
        if (!(value instanceof FileList)) {
          return this.createError({ message: translate('app.form.validation.filetypeInvalid') });
        }

        if (value.length === 0) {
          return this.createError({ message: translate('app.form.validation.selectFile') });
        }

        if (!Utils.File.isNdaEligble(value[0].type)) {
          return this.createError({ message: translate('app.form.validation.filetypeInvalid') });
        }

        return true;
      }),

      description: yup.string().max(300).required(translate('app.form.validation.required'))
    })
    .required();

  return formScheme;
};

const SendNdaModal = ({ modalProps, onConfirm, onCancel }: Props) => {
  const { translate } = useI18n();
  const formScheme = useFormScheme();
  const {
    register,
    getValues,
    reset,
    setValue,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    watch
  } = useForm<IUpdateProfileForm>({
    resolver: yupResolver(formScheme),
    mode: 'all',
    defaultValues: {
      description: ''
    }
  });

  const {
    isOpen: isOpenDiscardChanges,
    onOpen: onOpenDiscardChanges,
    onClose: onCloseDiscardChanges
  } = useDisclosure();

  const description = watch('description');

  function getFileSelected() {
    const file = getValues('nda');
    if (!file || file.length === 0) {
      return null;
    }

    return file[0];
  }

  const submit = async (values: IUpdateProfileForm) => {
    if (onConfirm) {
      onConfirm(values);
    }
    modalProps.onClose();
  };

  const handleCloseModal = () => {
    const ndaFile = getValues('nda');
    if ((ndaFile && ndaFile.length > 0) || getValues('description')) {
      onOpenDiscardChanges();
    } else {
      modalProps.onClose();
    }
  };

  const handleConfirmDiscardChanges = () => {
    if (onCancel) {
      onCancel();
    }
    reset();
    modalProps.onClose();
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement> | undefined) => {
    if (event) {
      const value = event.target.value.replace(/(\r\n|\n|\r)/gm, '');
      setValue('description', value);
    }
  };

  return (
    <Modal isOpen={modalProps.isOpen} onClose={handleCloseModal} size="2xl">
      <ModalOverlay />
      <ModalContent minW={{ sm: 438, lg: 544 }}>
        <ModalCloseButton />
        <ModalHeader>
          <Styles.ModalTitle>{translate('ndaCompany.modal.title')}</Styles.ModalTitle>
        </ModalHeader>

        <form onSubmit={handleSubmit(submit)}>
          <ModalBody>
            <VStack spacing="4">
              <Box w="full">
                <Text>{translate('ndaCompany.modal.subtitle')}</Text>
                <Text fontWeight="bold">{translate('ndaCompany.modal.descriptionSteps')}</Text>
              </Box>

              <Box w="full">
                <Styles.ModalTitle mb="4">{translate('ndaCompany.modal.title1')}</Styles.ModalTitle>
                <FormControl isInvalid={!!errors.description}>
                  <Textarea
                    {...register('description')}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    fontSize="sm"
                    onChange={handleTextAreaChange}
                    placeholder={translate('ndaCompany.modal.title1.placeholder')}
                  />
                  <Text textAlign="right">
                    {description.length} / {MAX_DESCRIPTION_LENGTH}
                  </Text>
                </FormControl>

                <Styles.ModalTitle mb="1">{translate('ndaCompany.modal.download.title')}</Styles.ModalTitle>

                <Text fontSize="sm" mb="4">
                  <Highlight
                    query={translate('ndaCompany.modal.download.descriptionPartial')!}
                    styles={{ fontWeight: 'bold', color: 'gray.900' }}
                  >
                    {translate('ndaCompany.modal.download.description')}
                  </Highlight>
                </Text>
                <LinkToDownload
                  href={API.Ndas.URLS.Common.ndaLatestDownload()}
                  placeholder={translate('migrate.inputDownload.placeholder')}
                />
              </Box>

              <Box w="full">
                <Styles.ModalTitle mb="1">{translate('ndaCompany.modal.upload.title')}</Styles.ModalTitle>
                <Text fontSize="sm" mb="4">
                  <Highlight
                    query={translate('ndaCompany.modal.upload.descriptionPartial')!}
                    styles={{ fontWeight: 'bold', color: 'gray.900' }}
                  >
                    {translate('ndaCompany.modal.upload.description')}
                  </Highlight>
                </Text>
                <InputFileForm
                  label={translate('ndaCompany.modal.upload.placeholder')!}
                  fieldError={errors.nda}
                  value={getFileSelected()}
                  inputProps={{ ...register('nda'), accept: Utils.File.getNdaSupportedExtensions() }}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" onClick={handleCloseModal}>
              {translate('app.actions.cancel')}
            </Button>
            <Button
              ml={3}
              type="submit"
              isLoading={isSubmitting}
              loadingText={translate('ndaCompany.modalSend.loadingButton')}
              isDisabled={!isValid}
            >
              {translate('app.actions.send')}
            </Button>
            <SaveChangesModal
              title={translate('components.modal.title.nda')}
              textContent={translate('components.modal.discard.nda')}
              modalProps={{
                isOpen: isOpenDiscardChanges,
                onClose: onCloseDiscardChanges
              }}
              onConfirm={handleConfirmDiscardChanges}
            />
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SendNdaModal;
