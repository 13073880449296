import { components, OptionProps } from 'chakra-react-select';
import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { CustomSelectOption, CustomSelectGroupOptions } from '../interfaces';

export const Option = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: OptionProps<CustomSelectOption<any>, true, CustomSelectGroupOptions<any>>) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex '
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={{
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
      }}
    >
      <Box mr={2} _hover={{ cursor: 'pointer' }}>
        {isSelected ? <MdCheckBox color="#3182ce" /> : <MdCheckBoxOutlineBlank />}
      </Box>
      {rest.data.renderLabel ? rest.data.renderLabel() : children}
    </components.Option>
  );
};
