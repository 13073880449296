import { ChakraProvider, ChakraProviderProps, ColorModeProvider, extendTheme } from '@chakra-ui/react';
import { useMemo } from 'react';
import { theme } from '../../theme';

export type UiThemeProviderProps = ChakraProviderProps;

/**
 * Theme provider, It already comes with a theme
 *
 * Can be overwritten by passing `theme` props
 * @see Docs https://chakra-ui.com/docs/styled-system/theming/theme
 */
export default function UiThemeProvider({ children, ...chakraProviderProps }: UiThemeProviderProps) {
  const themeSet = useMemo(() => extendTheme({ ...theme, ...chakraProviderProps.theme }), [chakraProviderProps.theme]);

  return (
    <ChakraProvider {...chakraProviderProps} theme={themeSet}>
      <ColorModeProvider>{children}</ColorModeProvider>
    </ChakraProvider>
  );
}
