export const keysSurveyPtBr = {
  'survey.modal.toastSuccessTitle': 'Pesquisa enviada com sucesso.',
  'survey.modal.toastSuccessDescription':
    'Obrigado por responder nossa pesquisa. Caso precise alterar alguma informação, você pode acessá-la em Perfil.',
  'survey.modal.start': 'Começar',
  'survey.modal.welcomeTitle': 'Boas vindas ao Portal do Desenvolvedor',
  'survey.modal.welcomeDescription':
    'Para que possamos direcionar soluções personalizadas e agregar mais valor para os seus clientes, participe da nossa pesquisa e nos ajude a entender as suas necessidades.',
  'survey.checkbox.input': 'Especifique'
};
