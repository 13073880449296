export const keysServicesEnUs = {
  'services.smartstore.title': 'SmartStore',
  'services.smartstore.titlePartial': 'Store',
  'services.smartstore.subtitle': 'Make your applications remotely available to endpoints',
  'services.smartstore.install': 'Remote installation and update on terminal',
  'services.smartstore.installPartial': 'Installation',
  'services.smartstore.management': 'Simple management with terminal registration via PIN/QR Code',
  'services.smartstore.managementPartial': 'Simple management',
  'services.smartstore.access': 'Access to the 24x7 subscription robot',
  'services.smartstore.accessPartial1': 'Access',
  'services.smartstore.accessPartial2': '24x7',
  'services.smartstore.apps': 'Manage app and versions available to your users',
  'services.smartstore.appsPartial': 'Manage app and versions',
  'services.smartstore.cardFree.title': 'Free',
  'services.smartstore.cardFree.subtitle': 'Ideal for starting to validate your solution',
  'services.smartstore.cardPublic.title': 'Public',
  'services.smartstore.cardPublic.subtitle': 'Ideal for growing businesses',
  'services.smartstore.cardPrivate.title': 'Private',
  'services.smartstore.cardPrivate.subtitle': 'Ideal for scaling the solution and reaching more customers',
  'services.smartstore.card.devices': 'Devices',
  'services.smartstore.card.post': 'Publish',
  'services.smartstore.card.posts': 'Posts',
  'services.smartstore.card.supportSla': 'Service SLA',
  'services.smartstore.card.supportTicket': 'Support via ticket',
  'services.smartstore.card.supportTicketPartial': '(Help Center)',
  'services.smartstore.card.supportChatPhone': 'Support via Phone/Chat',
  'services.smartstore.card.button': 'Request',
  'services.smartstore.card.update': 'Automatic Update',

  'services.mymdm.title': 'MyMDM',
  'services.mymdm.titlePartial': 'MDM',
  'services.mymdm.subtitle': 'Endpoint monitoring and management platform',
  'services.mymdm.monitoring': 'Remote monitoring of terminals in real time through different dashboards',
  'services.mymdm.monitoringPartial': 'Monitoring',
  'services.mymdm.settings':
    'Remote configuration and the option to enable or disable terminal configuration functions',
  'services.mymdm.settingsPartial': 'Remote Setting',
  'services.mymdm.custom': 'Remote launcher customization and application access options',
  'services.mymdm.customPartial': 'Remote launcher customization',
  'services.mymdm.install': 'Remote installation and update of applications',
  'services.mymdm.installPartial': 'Remote installation and update',
  'services.mymdm.support': 'Remote Support available by device and user',
  'services.mymdm.supportPartial': 'Remote Support',
  'services.mymdm.panel': 'Create customized dashboards and reports for data analysis according to your interests',
  'services.mymdm.panelPartial': 'Create custom dashboards and reports',
  'services.mymdm.cost': 'Reduce operational cost with installations, service and support incidents',
  'services.mymdm.costPartial': 'Reduce cost',
  'services.mymdm.increase':
    'Increase availability of use and ensure high terminal performance through preventive monitoring and scheduled updates',
  'services.mymdm.increasePartial': 'Increase',

  'services.mymdm.footerDescription':
    'MyMDM is a complete tool that allows the customer or partner to remotely manage their terminal base, including terminal monitoring and configuration resources and the distribution or updating of applications through MyMDM itself or through the Smart Store.',
  'services.mymdm.footerDescriptionPartial1': 'MyMDM',
  'services.mymdm.footerDescriptionPartial2': 'Smart Store',
  'services.mymdm.footerActionButton': "I'm interested"
};
