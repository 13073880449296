import { IAppLocalStorage } from './types';

export class AppLocalStorage {
  // [name: string]: any;
  // eslint-disable-next-line class-methods-use-this
  get length() {
    return localStorage.length;
  }

  static clear(): void {
    localStorage.clear();
  }

  static key(index: number): string | null {
    return localStorage.key(index);
  }

  static removeItem(
    key: keyof IAppLocalStorage,
    typeStorage: 'localStorage' | 'sessionStorage' = 'localStorage'
  ): void {
    const storage = typeStorage === 'sessionStorage' ? sessionStorage : localStorage;
    storage.removeItem(key);
  }

  static setItem = <T extends keyof IAppLocalStorage>(
    key: T,
    value: IAppLocalStorage[T],
    typeStorage: 'localStorage' | 'sessionStorage' = 'localStorage'
  ) => {
    const storage = typeStorage === 'sessionStorage' ? sessionStorage : localStorage;
    storage.setItem(key, JSON.stringify(value));
  };

  static getItem = <T extends keyof IAppLocalStorage>(
    key: T,
    typeStorage: 'localStorage' | 'sessionStorage' = 'localStorage'
  ): IAppLocalStorage[T] | undefined => {
    const storage = typeStorage === 'sessionStorage' ? sessionStorage : localStorage;
    const value = storage.getItem(key);

    return value ? JSON.parse(value) : undefined;
  };
}
