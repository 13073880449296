import { Text, Box, Progress } from '@chakra-ui/react';

export default function ProgressStepper({ totalItems, currentItems }: { totalItems: number; currentItems: number }) {
  return (
    <Box w="full" display="flex" flexDirection="column">
      <Progress value={(currentItems / totalItems) * 100} size="xs" mb={1} />
      <Box display="flex" justifyContent="center">
        <Text color="gray.900" fontSize="sm" fontWeight="medium">
          {currentItems} de {totalItems}
        </Text>
      </Box>
    </Box>
  );
}
