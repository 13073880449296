import { ChevronDownIcon, ChevronRightIcon, Icon } from '@chakra-ui/icons';
import { TbPointFilled } from 'react-icons/tb';
import { NodeIconProps } from '../interfaces';

export const NodeIcon = ({ hasChildren, isOpen }: NodeIconProps) => {
  if (!hasChildren) {
    return <Icon as={TbPointFilled} boxSize={3} />;
  }
  if (isOpen) {
    return <Icon as={ChevronDownIcon} boxSize={6} />;
  }
  return <Icon as={ChevronRightIcon} boxSize={6} />;
};
