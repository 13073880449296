import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, APIModels } from 'src/shared/api';

const QUERY_ROOT = 'survey';

const useSurveyQuery = () => {
  const queryClient = useQueryClient();

  const getSurvey = () =>
    useQuery({
      queryKey: [QUERY_ROOT],
      queryFn: ({ signal }) => API.Surveys.get({ signal }).response
    });

  const answerSurvey = useMutation({
    mutationFn: (data: APIModels.IAnswerDTO) => API.Surveys.submitAnswers(data).response,
    onSuccess: () => queryClient.invalidateQueries([QUERY_ROOT, 'answers'])
  });

  return {
    getSurvey,
    answerSurvey
  };
};

export default useSurveyQuery;
