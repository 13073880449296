export const keysDocumentPtBr = {
  'document.category.payment': 'Meios de pagamento',
  'document.category.automation': 'Automação comercial',
  'document.category.selectCategory': 'Selecione uma categoria',
  'document.title': 'Artigos de Desenvolvimento',
  'document.description':
    'Procure por artigos e documentações mais recentes, incluindo código de exemplos, tutoriais e referências de APIs.',
  'document.projectFilter.option': 'Selecione um projeto (opcional)',
  'document.inputSearchWord.loadingMessage': 'Procurando artigos...',
  'document.inputSearchWord.notFoundArticles': 'Informação não encontrada',
  'document.inputSearchWord.placeholder': 'Pesquisar',
  'document.error.forbidden.title': 'Sem permissão para esta documentação',
  'document.error.forbidden.description': 'Verifique o perfil da empresa para liberar o acesso.'
};
