import { Center, CenterProps, Image, Text, VStack } from '@chakra-ui/react';
import { useI18n } from 'src/i18n';

interface NotFoundMessageProps {
  message?: string;
  containerProps?: CenterProps;
}
const NotFoundMessage = ({ message, containerProps }: NotFoundMessageProps) => {
  const { translate } = useI18n();
  return (
    <Center {...containerProps}>
      <VStack>
        <Image mb={5} src="/no-result-found.svg" />
        <Text>{message || translate('app.result.notfound')}</Text>
      </VStack>
    </Center>
  );
};

export default NotFoundMessage;
