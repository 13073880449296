import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const Body: React.FC<{ children: React.ReactNode } & BoxProps> = ({ children, ...props }) => {
  return (
    <Box mx={6} {...props}>
      {children}
    </Box>
  );
};

export default {
  Body
};
