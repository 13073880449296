import ApiUtils from './apiUtils/ApiUtils';
import DateUtils from './dateUtils/DateUtils';
import FileUtils from './fileUtils/FileUtils';
import StringUtils from './stringUtils/StringUtils';
import UrlUtils from './urlUtils/UrlUtils';

export const Utils = {
  String: StringUtils,
  Url: UrlUtils,
  File: FileUtils,
  Api: ApiUtils,
  Date: DateUtils
};
