import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps
} from '@chakra-ui/react';
import { useI18n } from 'src/i18n';

interface SaveChangesModalProps {
  textContent: string;
  title?: string;
  modalProps: Omit<ModalProps, 'children'>;
  onConfirm?: () => void;
  onDeny?: () => void;
}

const SaveChangesModal = ({
  title,
  textContent,
  modalProps,
  onConfirm = () => null,
  onDeny = () => null
}: SaveChangesModalProps) => {
  const { translate } = useI18n();

  const confirmHandler = () => {
    onConfirm();
    modalProps.onClose();
  };

  const denyHandler = () => {
    onDeny();
    modalProps.onClose();
  };

  let modalTitle = '';
  if (title) {
    modalTitle = title;
  } else {
    modalTitle = translate('components.modal.title');
  }

  return (
    <Modal isOpen={modalProps.isOpen} onClose={modalProps.onClose}>
      <ModalOverlay />
      <ModalContent data-testid="@save-changes-modal">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{textContent}</ModalBody>

        <ModalFooter>
          <Button data-testid="@save-changes-modal-no" variant="outline" mr={3} onClick={denyHandler}>
            {translate('app.label.no')}
          </Button>
          <Button data-testid="@save-changes-modal-yes" onClick={confirmHandler}>
            {translate('app.label.yes')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveChangesModal;
