import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { MenuProps, components } from 'chakra-react-select';
import { useI18n } from 'src/i18n';
import { CustomSelectGroupOptions, CustomSelectOption } from '../interfaces';

function getLength(options: readonly (CustomSelectGroupOptions<any> | CustomSelectOption<any>)[]): number {
  return options.reduce((acc, curr) => {
    if ('options' in curr) return acc + getLength(curr.options);
    return acc + 1;
  }, 0);
}

export const Menu = ({
  children,
  ...props
}: MenuProps<CustomSelectOption<any>, true, CustomSelectGroupOptions<any>>) => {
  const clearSelections = () => props.setValue([], 'deselect-option');
  const { translate } = useI18n();

  return (
    <components.Menu<CustomSelectOption<any>, true, CustomSelectGroupOptions<any>> {...props}>
      {children}
      <Box py={4} px={2}>
        <Flex>
          <Button variant="link" size="xs" onClick={clearSelections}>
            {translate('components.customSelect.clearSelection')}
          </Button>
          <Spacer />
          <Text fontSize="xs">
            {translate('components.customSelect.xOfy', {
              quantity: props.getValue().length,
              total: getLength(props.options)
            })}
          </Text>
        </Flex>
      </Box>
    </components.Menu>
  );
};
