import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Portal,
  Slide,
  Tooltip,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react';
import React from 'react';
import { useI18n } from 'src/i18n';

interface ISlideModalProps {
  children: (props: { onClose: () => void }) => React.ReactNode;
  onClose?: () => void;
}

const SlideModal = ({ children, onClose: onCloseModal }: ISlideModalProps) => {
  const { translate } = useI18n();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref: any = React.useRef();
  const zIndexLevel: number = 1000;

  useOutsideClick({
    ref,
    handler: closeModal
  });

  function closeModal() {
    onClose();
    if (onCloseModal) {
      onCloseModal();
    }
  }

  return (
    <Portal>
      {isOpen && (
        <Box
          zIndex={zIndexLevel}
          w="full"
          h="full"
          position="fixed"
          right={0}
          top={0}
          bg={isOpen ? 'rgba(0, 0, 0, 0.4)' : ''}
        />
      )}
      <Slide
        direction="right"
        in={isOpen}
        style={{
          zIndex: zIndexLevel + 1,
          display: 'flex',
          alignItems: 'center',
          right: isOpen ? 0 : 30,
          width: isOpen ? '100%' : '50%',
          height: '100%',
          justifyContent: isOpen ? 'center' : 'flex-start'
        }}
      >
        <Box
          transitionDuration="200ms"
          position="absolute"
          ref={ref}
          p="2rem"
          paddingBottom="3rem"
          w={{ base: 'full', lg: '58.25rem' }}
          minH={{ base: '65%', md: '600px' }}
          color="white"
          bg="white"
          rounded="md"
          shadow="md"
          display="flex"
          flexDir="column"
        >
          <Flex w="full" justifyContent={isOpen ? 'flex-end' : ''}>
            {!isOpen && (
              <Tooltip label={translate('slide_modal.tooltip')} placement="auto">
                <Button
                  bg="none"
                  leftIcon={<WarningIcon fontSize={30} color="red" />}
                  color="red"
                  position="absolute"
                  left="-32px"
                  top="48px"
                  onClick={onToggle}
                />
              </Tooltip>
            )}
            <CloseButton position="absolute" right="30px" top="25px" color="black" onClick={closeModal} />
          </Flex>
          <Flex flexDir="column" flex="1" w="full" h="full" visibility={isOpen ? 'visible' : 'hidden'}>
            {children({ onClose })}
          </Flex>
        </Box>
      </Slide>
    </Portal>
  );
};

export default SlideModal;
