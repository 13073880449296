import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { ButtonProps } from '@chakra-ui/react';
import * as Styles from './styles';

interface HeaderButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const HeaderButton = ({ children, ...props }: HeaderButtonProps) => {
  return (
    <Styles.ChakraButton leftIcon={<AddIcon />} {...props}>
      {children}
    </Styles.ChakraButton>
  );
};
