import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
  InputRightElementProps,
  Mark,
  useToken
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import UiInput from '../UiInput/UiInput';
import * as Styles from './styles';

type Props = {
  label?: string;
  className?: string;
  helperText?: string;
  fieldError?: FieldError | undefined;
  canHighlightedValid?: boolean;
  inputProps: InputProps;
  inputGroupProps?: InputGroupProps;
  inputRightElementProps?: InputRightElementProps;
  dataTestId?: string;
  isRegisterConfirmPasswordField?: boolean;
  formControlProps?: FormControlProps;
  isDisabled?: boolean;
  formLabelProps?: FormLabelProps;
  optionalField?: string;
};

export default function InputForm({
  isDisabled,
  label,
  fieldError,
  className,
  helperText,
  inputProps,
  inputGroupProps,
  inputRightElementProps,
  canHighlightedValid,
  dataTestId,
  formControlProps,
  formLabelProps,
  optionalField
}: Props) {
  const [isShowValue, setIsShowValue] = useState(false);
  const [value, setValue] = useState('');
  const [highlightColor] = useToken('colors', ['blue.500']);

  function onChangeVisibilityPassword() {
    setIsShowValue(!isShowValue);
  }

  function isHighlighted() {
    return canHighlightedValid && value && !fieldError;
  }

  function getType() {
    if (inputProps.type === 'password') {
      return isShowValue ? 'text' : 'password';
    }
    return inputProps.type;
  }

  return (
    <FormControl {...formControlProps} isInvalid={!!fieldError} className={className}>
      {(label || optionalField) && (
        <FormLabel color="gray.900" {...formLabelProps}>
          {label}

          {optionalField && (
            <Mark color="gray.400" ml={2} fontSize={14}>
              {optionalField}
            </Mark>
          )}
        </FormLabel>
      )}

      <InputGroup {...inputGroupProps}>
        <UiInput
          {...inputProps}
          type={getType()}
          onChange={(e) => {
            setValue(e.target.value);
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
          }}
          focusBorderColor={highlightColor}
          boxShadow={isHighlighted() ? `0 0 0 1px ${highlightColor}` : ''}
          data-testid={dataTestId}
          isDisabled={isDisabled}
        />

        {inputRightElementProps && <InputRightElement {...inputRightElementProps} />}

        {inputProps.type === 'password' && (
          <InputRightElement>
            <Styles.ButtonIconPassword onClick={() => onChangeVisibilityPassword()}>
              {isShowValue ? <ViewIcon /> : <ViewOffIcon />}
            </Styles.ButtonIconPassword>
          </InputRightElement>
        )}
      </InputGroup>

      {!fieldError && helperText && (
        <FormHelperText color={isHighlighted() ? highlightColor : ''}>{helperText}</FormHelperText>
      )}
      {fieldError && <FormErrorMessage>{fieldError?.message}</FormErrorMessage>}
    </FormControl>
  );
}
