import { AxiosRequestConfig } from 'axios';
import { IListRequestsDTO, IListRequestsParams, IListRequestStatusDTO, IListRequestTypesDTO } from '../../models';
import { createGetRequest } from '../shared';

export const URLS = {
  listRequest: () => '/jira/requests',
  listStatus: () => '/jira/requests/status',
  listTypes: () => '/jira/requests/types'
};

export const list = (data: IListRequestsParams, axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListRequestsDTO>({ url: URLS.listRequest(), queryParams: data, axiosRequestConfig });
};

export const listStatus = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListRequestStatusDTO[]>({ url: URLS.listStatus(), axiosRequestConfig });
};

export const listTypes = (axiosRequestConfig?: AxiosRequestConfig) => {
  return createGetRequest<IListRequestTypesDTO[]>({ url: URLS.listTypes(), axiosRequestConfig });
};
