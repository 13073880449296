import { useQuery } from '@tanstack/react-query';
import { API } from 'src/shared/api';

const QUERY_ROOT = 'tree-view';
const QUERY_ATTEMPTS = 3;

const useTreeViewQuery = () => {
  const listContentIndex = (spaceId: string, articleId?: string) =>
    useQuery({
      queryKey: [QUERY_ROOT, 'index', spaceId, articleId],
      queryFn: ({ signal }) => API.Atlassian.listProjectIndex({ spaceId, articleId }, { signal }).response,
      retry: QUERY_ATTEMPTS
    });

  return {
    listContentIndex
  };
};

export default useTreeViewQuery;
