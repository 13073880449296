import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled(Flex).attrs<FlexProps>(() => ({
  flexDirection: 'column',
  bg: 'white',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.045)',
  w: '74px',
  h: 'calc(100vh - 64px)',
  justifyContent: 'space-between'
}))``;

export const TextVersion = styled(Text).attrs({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 10,
  color: 'gray.500',
  marginTop: 5,
  marginBottom: 3
} as TextProps)``;
