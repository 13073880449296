import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { DropdownMenu } from './DropdownMenu';

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

export const Dropdown = ({
  children,
  isOpen,
  target,
  onClose
}: {
  children?: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}) => {
  return (
    <Box position="relative">
      {target}
      {isOpen ? <DropdownMenu>{children}</DropdownMenu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </Box>
  );
};
