import { WithNav } from './components';
import AppProviders from './providers';
import AppRouter from './router/AppRouter';

function AppModule() {
  return (
    <AppProviders>
      <WithNav>
        <AppRouter />
      </WithNav>
    </AppProviders>
  );
}

export default AppModule;
