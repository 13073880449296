import { Box, Show } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavOptions } from 'src/AppModule/components/WithNav/NavOptions';
import { useI18n } from 'src/i18n';
import UiSelect from '../UiSelect/UiSelect';

export const NavSelect = () => {
  const navigate = useNavigate();
  const { translate } = useI18n();

  const selectOptions = [
    {
      label: translate('app.navbar.selectNavbarOption'),
      value: null
    },
    ...NavOptions.map((n) => {
      if ('component' in n) {
        return {
          label: translate(n.label),
          options: n.links.map((l) => ({
            label: translate(l.label),
            value: {
              isExternal: l.isExternal,
              url: l.url
            }
          }))
        };
      }

      return {
        label: translate(n.label),
        value: {
          isExternal: n.isExternal,
          url: n.url
        }
      };
    })
  ];

  return (
    <Show below="md">
      <Box p="4" mb={{ base: '4', md: '0' }}>
        <UiSelect
          defaultValue={selectOptions[0]}
          isSearchable={false}
          options={selectOptions}
          formatGroupLabel={(data) => translate(data.label!)}
          onChange={(newValue) => {
            if (newValue?.value?.isExternal && newValue?.value?.url) {
              window.open(newValue.value.url, '_blank');
              return;
            }
            if (newValue?.value?.url) {
              navigate(newValue!.value!.url!);
            }
          }}
        />
      </Box>
    </Show>
  );
};
