import { useContext, useState } from 'react';
import { useSurveyQuery } from 'src/AppModule/hooks';
import { UserProviderContext } from 'src/AppModule/providers/UserProvider/UserProvider';
import { useI18n } from 'src/i18n';
import { SlideModal } from 'src/shared/components';
import { APIModels } from 'src/shared/api';
import { useToastVariants } from 'src/shared/hooks';
import { Utils } from 'src/shared-hold/utils';
import SurveyForm from '../SurveyForm/SurveyForm';

export function SurveyModal() {
  const { setUser, user } = useContext(UserProviderContext);
  const { translate } = useI18n();
  const { toastSuccess, toastError } = useToastVariants();
  const surveyQuery = useSurveyQuery();
  const { data: survey } = surveyQuery.getSurvey();
  const [observableOnClose, setObservableOnClose] = useState(false);

  if (!survey) {
    return <></>;
  }

  async function finishSurvey(answers: APIModels.IAnswerQuestionDTO[], closeSurvey?: () => void) {
    const data: APIModels.IAnswerDTO = {
      id: survey!.id,
      questions: answers
    };
    try {
      await surveyQuery.answerSurvey.mutateAsync(data);
      toastSuccess({
        isClosable: true,
        title: translate('survey.modal.toastSuccessTitle'),
        description: translate('survey.modal.toastSuccessDescription')
      });
      if (closeSurvey) {
        closeSurvey();
      }
      if (user) {
        setUser({ ...user, info: { ...user.info, isNecessaryToAnswerSurvey: false } });
      }
    } catch (error) {
      toastError({ title: translate(Utils.Api.translateErrorJsonKey(error)) });
    }
  }

  return (
    <SlideModal onClose={() => setObservableOnClose(!observableOnClose)}>
      {(props) => (
        <SurveyForm
          observableOnClose={observableOnClose}
          onCloseSurvey={props.onClose}
          onSurveyAnswered={finishSurvey}
          survey={survey}
        />
      )}
    </SlideModal>
  );
}
