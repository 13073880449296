import styled from 'styled-components';
import { Box, BoxProps, FormLabel, FormLabelProps, InputGroup as InputGroupChakra } from '@chakra-ui/react';

export const Content = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  py: '5'
} as BoxProps)`` as typeof Box;

export const Text = styled(Box).attrs({
  ml: 2,
  fontWeight: 600,
  color: 'blue.800'
})`` as typeof Box;

export const TextFilename = styled(Box).attrs({
  ml: 2,
  fontWeight: 'medium',
  color: 'blue.800'
} as BoxProps)`` as typeof Box;

export const Label = styled(FormLabel).attrs({
  variant: 'outline',
  w: 'full',
  size: 'lg',
  fontSize: 'sm',
  fontWeight: 'normal',
  borderRadius: 0,
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: 'gray.900',
  _hover: {
    bg: 'gray.50',
    cursor: 'pointer'
  }
} as FormLabelProps)``;

export const InputGroup = styled(InputGroupChakra).attrs({
  width: 0,
  height: 0,
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1
})``;
