import { Button } from './Button';
import { Card } from './Card';
import { Checkbox } from './Checkbox';
import { Heading } from './Heading';
import { Input } from './Input';
import { Progress } from './Progress';
import { Radio } from './Radio';
import { Spinner } from './Spinner';
import { Table } from './Table';
import { Text } from './Text';

export default {
  Button,
  Input,
  Heading,
  Text,
  Checkbox,
  Card,
  Table,
  Radio,
  Progress,
  Spinner
};
