import React, { useMemo, useState } from 'react';
import { APIModels } from 'src/shared/api';
import { AppLocalStorage } from 'src/shared/utils/appLocalStorage/appLocalStorage';

interface IUserProviderContext {
  user?: APIModels.IAuthUserDTO;
  setUser: (user: APIModels.IAuthUserDTO) => unknown;
  removeUser: () => unknown;
}

const UserProviderContext = React.createContext(null) as unknown as React.Context<IUserProviderContext>;

interface IUserProviderProps {
  user?: APIModels.IAuthUserDTO;
  children?: React.ReactNode;
}

const UserProvider: React.FC<IUserProviderProps> = ({ user: initialUser, children }) => {
  const [localUser, setLocalUser] = useState(initialUser);

  const removeUser = () => {
    const typeStorage = localUser?.auth.stayConnected ? 'localStorage' : 'sessionStorage';
    AppLocalStorage.removeItem('APP:USER', typeStorage);
    AppLocalStorage.removeItem('APP:SESSION:STAY_CONNECTED', 'localStorage');
    setLocalUser(undefined);
  };

  const setUser = (user: APIModels.IAuthUserDTO) => {
    const typeStorage = user.auth.stayConnected ? 'localStorage' : 'sessionStorage';
    AppLocalStorage.setItem('APP:USER', user, typeStorage);
    setLocalUser(user);
  };

  const providerValue = useMemo<IUserProviderContext>(
    () => ({
      user: localUser,
      setUser,
      removeUser
    }),
    [localUser, setUser, removeUser]
  );

  return <UserProviderContext.Provider value={providerValue}>{children}</UserProviderContext.Provider>;
};

export { UserProviderContext, UserProvider };
