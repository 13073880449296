import { InternalAxiosRequestConfig } from "axios";
import { useCallback, useEffect } from "react";
import { appAxios } from "src/shared/api";

const useAuthTokenInterceptor = (auth: {
  userToken?: string;
  userJiraToken?: string | null;
}) => {
  const interceptor = useCallback(
    (config: InternalAxiosRequestConfig) => {
      if (auth.userToken) {
        config.headers!.Token = auth.userToken;
      } else {
        delete config.headers!.Token;
      }

      if (auth.userJiraToken) {
        config.headers!["Jira-Token"] = auth.userJiraToken;
      } else {
        delete config.headers!["Jira-Token"];
      }

      return config;
    },
    [auth.userToken]
  );

  useEffect(() => {
    const token = appAxios.interceptors.request.use(interceptor);
    const unsubscribe = () => {
      appAxios.interceptors.request.eject(token);
    };
    return unsubscribe;
  }, [interceptor]);
};

export default useAuthTokenInterceptor;
