import { Icon, Flex, Spacer, Divider, Box, InputProps } from '@chakra-ui/react';
import { FieldError, Control } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { InputForm, InputMaskForm, SelectFormOption, SelectBasicForm } from 'src/shared/components';
import { IUpdateProfileForm } from '../../interfaces';

export function WithIconEdit({
  label,
  fieldError,
  inputProps,
  placeholder,
  optionalField
}: {
  label: string;
  placeholder: string;
  fieldError?: FieldError;
  inputProps: InputProps;
  optionalField?: string;
}) {
  return (
    <InputForm
      label={label}
      inputRightElementProps={{
        pr: 10,
        children: <Icon w={5} h={5} as={MdEdit} color="blue.600" />
      }}
      optionalField={optionalField}
      formLabelProps={{
        fontWeight: 'semibold',
        fontSize: 'sm',
        pl: '6',
        mb: '0'
      }}
      fieldError={fieldError}
      inputProps={{ ...inputProps, variant: 'flushed', px: '6', placeholder }}
    />
  );
}

export function WithMaskAndWithIconEdit({
  label,
  fieldError,
  inputProps,
  placeholder,
  mask
}: {
  label: string;
  placeholder: string;
  fieldError?: FieldError;
  inputProps: InputProps;
  mask: 'telephone';
}) {
  return (
    <InputMaskForm
      label={label}
      mask={mask}
      inputRightElementProps={{
        pr: 10,
        children: <Icon w={5} h={5} as={MdEdit} color="blue.600" />
      }}
      formLabelProps={{
        fontWeight: 'semibold',
        fontSize: 'sm',
        requiredIndicator: <></>,
        pl: '6',
        mb: '0'
      }}
      fieldError={fieldError}
      placeholder={placeholder}
      inputProps={{ ...inputProps, variant: 'flushed', px: '6' }}
    />
  );
}

export function SelectWithIconEdit({
  label,
  fieldError,
  placeholder,
  options,
  control,
  nameFieldControl
}: {
  label: string;
  placeholder: string;
  fieldError?: FieldError;
  options: SelectFormOption[];
  control: Control<IUpdateProfileForm, any>;
  nameFieldControl: keyof IUpdateProfileForm;
}) {
  return (
    <Box my={3} w="full">
      <Flex alignItems="center" pr="6" pl="2">
        <SelectBasicForm
          placeholder={placeholder}
          controlProps={{ control, name: nameFieldControl }}
          fieldError={fieldError}
          label={label}
          options={options}
          variant="none"
          formControlProps={{
            w: 'xs'
          }}
          formLabelProps={{
            fontWeight: 'semibold',
            fontSize: 'sm',
            ml: '4'
          }}
        />
        <Spacer />

        <Icon w={5} h={5} as={MdEdit} color="blue.600" ml="4" mr="1.5" />
      </Flex>
      <Divider borderColor="blackAlpha.200" size="xl" />
    </Box>
  );
}
