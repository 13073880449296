import { Input, InputProps } from '@chakra-ui/react';
import React from 'react';

const UiInput = React.forwardRef<HTMLInputElement, InputProps>(({ ...props }, ref) => (
  <Input isDisabled={props.isDisabled} ref={ref} focusBorderColor={props.focusBorderColor || 'blue.500'} {...props} />
));
// Giving chakra ID
(UiInput as any).id = 'Input';

export default UiInput;
