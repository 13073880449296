import React, { useEffect, useRef } from 'react';
import { Box, RadioGroup, Radio, Image, Flex, Grid, GridItem } from '@chakra-ui/react';
import imgSatisfaction1 from 'src/shared/assets/imgs/satisfaction_level_1.svg';
import imgSatisfaction2 from 'src/shared/assets/imgs/satisfaction_level_2.svg';
import imgSatisfaction3 from 'src/shared/assets/imgs/satisfaction_level_3.svg';
import imgSatisfaction4 from 'src/shared/assets/imgs/satisfaction_level_4.svg';
import imgSatisfaction5 from 'src/shared/assets/imgs/satisfaction_level_5.svg';
import { IAnswerAlternativeDTO } from 'src/shared/api/models';
import { SurveyAlternativesProps } from '../interfaces';

export default function SurveyRadioSatisfaction({ alternatives, selected, onChange }: SurveyAlternativesProps) {
  const [values, setValues] = React.useState<IAnswerAlternativeDTO[]>([]);
  const ref1 = useRef<HTMLInputElement>();
  const ref2 = useRef<HTMLInputElement>();
  const ref3 = useRef<HTMLInputElement>();
  const ref4 = useRef<HTMLInputElement>();
  const ref5 = useRef<HTMLInputElement>();

  useEffect(() => {
    if (selected.length > 0) {
      updateRadioValue(selected[0]?.id.toString());
    }
  }, [alternatives]);

  useEffect(() => {
    onChange(values);
  }, [values]);

  function updateRadioValue(e: string) {
    const updateOption: IAnswerAlternativeDTO = {
      id: parseInt(e, 10)
    };
    setValues([updateOption]);
  }

  const refs = [ref1, ref2, ref3, ref4, ref5];

  const satisfactionLevels = [
    {
      imgSrc: imgSatisfaction1,
      value: alternatives[0]?.id || -1
    },
    {
      imgSrc: imgSatisfaction2,
      value: alternatives[1]?.id || -2
    },
    {
      imgSrc: imgSatisfaction3,
      value: alternatives[2]?.id || -3
    },
    {
      imgSrc: imgSatisfaction4,
      value: alternatives[3]?.id || -4
    },
    {
      imgSrc: imgSatisfaction5,
      value: alternatives[4]?.id || -5
    }
  ];

  const onIconClick = (index: number) => {
    if (refs[index]?.current) {
      refs[index]?.current?.click();
    }
  };

  return (
    <Box color="blue.900" minW={{ base: '480px', md: '800px' }} px={5}>
      <RadioGroup onChange={(e) => updateRadioValue(e)} value={values[0]?.id.toString()}>
        <Grid templateColumns={{ base: 'repeat(5, 1fr)' }} gap={6}>
          {satisfactionLevels?.map((item, index) => {
            return (
              <GridItem key={index} gap={4}>
                <Flex flexDir="column" alignItems="center">
                  <Image src={item.imgSrc} cursor="pointer" onClick={() => onIconClick(index)} />
                  <Radio
                    value={item.value.toString()}
                    ref={refs[index] as any}
                    onClick={(e) => {
                      e.preventDefault();
                      onIconClick(index);
                    }}
                    flexDir="column-reverse"
                  />
                </Flex>
              </GridItem>
            );
          })}
        </Grid>
      </RadioGroup>
    </Box>
  );
}
