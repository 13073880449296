import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '1.5rem',
    fontWeight: 'medium'
  },
  variants: {
    solid: (theme) => {
      let bg: string = `${theme.colorScheme}.800`;
      if (theme.colorScheme === 'red') {
        bg = `${theme.colorScheme}.500`;
      }

      return {
        bg,
        color: 'white',
        fontWeight: 600,
        _active: null,
        _hover: null,
        _disabled: {
          bg: 'gray.100',
          color: 'gray.900',
          fontWeight: 600
        }
      };
    },

    outline: (theme) => {
      return {
        borderColor: `${theme.colorScheme}.800`,
        color: `${theme.colorScheme}.800`,
        _active: null,
        _hover: null,
        fontWeight: 600
      };
    },

    link: (theme) => {
      return {
        color: `${theme.colorScheme}.800`,
        _active: null,
        _hover: null
      };
    }
  }
});
