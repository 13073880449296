import { useUserAuth } from 'src/AppModule/hooks';
import { SurveyModal } from './SurveyModal/SurveyModal';

export default function Survey() {
  const { user } = useUserAuth();

  if (!user?.info?.isNecessaryToAnswerSurvey) {
    return <></>;
  }

  return <SurveyModal />;
}
