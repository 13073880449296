import { Link } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from 'src/AppModule/hooks';
import { useI18n } from 'src/i18n';
import { Utils } from 'src/shared-hold/utils';
import { API, APIModels } from 'src/shared/api';
import { SpinnerLoading } from 'src/shared/components';
import { useToastVariants } from 'src/shared/hooks';
import { AppLocalStorage } from 'src/shared/utils/appLocalStorage/appLocalStorage';

const UnAuthGuard: React.FC = () => {
  const { translate } = useI18n();
  const redirect = useCallback(() => <Navigate to="/home" replace />, []);
  const { isSignedIn, signIn } = useUserAuth();
  const { toastInfo, toast } = useToastVariants();
  const toastId = 'user-forum-registered';

  useEffect(() => {
    if (isSignedIn) {
      return;
    }

    const jiraToken = Utils.Url.getUrlParam('jiraToken');
    const stayConnected = Utils.Url.getUrlParam('stayConnected');
    const tokenUrl = Utils.Url.getUrlParam('token');
    const wasForumRegistered = Utils.Url.getUrlParam('wasForumRegistered');
    if (!tokenUrl) {
      redirectToLandingPage();
      return;
    }

    AppLocalStorage.setItem('APP:SESSION:STAY_CONNECTED', !!stayConnected, 'localStorage');

    const { response } = API.Users.getLoggedUser({ headers: { Token: tokenUrl } });
    response
      .then((data) => {
        if (wasForumRegistered === 'true') {
          showToastForumRegistered();
        }
        if (data.info.isNecessaryCompleteAddress) {
          showToastCompleteAddress();
        }
        return setLoggedUser(data, !!stayConnected, tokenUrl, jiraToken);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === APIModels.StatusCodes.unauthorized) {
          redirectToLandingPage();
        }
      });
  }, []);

  function setLoggedUser(
    data: APIModels.ILoggedUserDTO,
    stayConnected: boolean,
    token: string,
    jiraToken: string | null
  ) {
    signIn({
      id: data.id,
      idCompany: data.idCompany,
      companyName: data.companyName,
      name: data.name,
      type: data.type,
      auth: {
        jiraToken,
        token,
        stayConnected
      },
      info: {
        isNecessaryToAnswerSurvey: data.info.isNecessaryToAnswerSurvey,
        hasAccessToNda: data.info.hasAccessToNda
      }
    });
  }

  function redirectToLandingPage() {
    window.location.assign(`${process.env.REACT_APP_LANDING_PAGE}/login?shouldInvalidateToken=true`);
  }

  function showToastForumRegistered() {
    if (!toast.isActive(toastId)) {
      toastInfo({
        title: translate('app.notifications.forum.welcome'),
        duration: null,
        isClosable: true,
        description: (
          <>
            {translate('app.notifications.forum.registered')}{' '}
            <Link isExternal href={process.env.REACT_APP_FORUM_GERTEC}>
              {process.env.REACT_APP_FORUM_GERTEC}
            </Link>
          </>
        )
      });
    }
  }

  function showToastCompleteAddress() {
    toastInfo({
      title: translate('app.notifications.formProfileUpdated.title'),
      duration: null,
      isClosable: true,
      description: translate('app.notifications.formProfileUpdated.description')
    });
  }

  if (isSignedIn) {
    return redirect();
  }

  return <SpinnerLoading />;
};

export default UnAuthGuard;
