import { CreatableSelect, GroupBase, Props, Select, SelectInstance } from 'chakra-react-select';
import { forwardRef, Ref } from 'react';
import { useI18n } from 'src/i18n';

type CustomRef<Option, IsMulti extends boolean> =
  | ((instance: SelectInstance<Option, IsMulti, GroupBase<Option>>) => void)
  | Ref<SelectInstance<Option, IsMulti, GroupBase<Option>>>
  | null;

export interface UiCreatableSelectProps<Option, IsMulti extends boolean> extends Props<Option, IsMulti> {
  ref?: CustomRef<Option, IsMulti>;
}

const FComponent = forwardRef(
  <Option, IsMulti extends boolean = false>(
    props: UiCreatableSelectProps<Option, IsMulti>,
    ref: CustomRef<Option, IsMulti>
  ) => {
    const { translate } = useI18n();
    return (
      <CreatableSelect
        {...props}
        useBasicStyles
        ref={ref}
        formatCreateLabel={(value) => `${translate('users.modal.invite.addMessage')}: ${value}`}
        focusBorderColor="blue.500"
        colorScheme="blue"
        selectedOptionColor="blue"
      />
    );
  }
);

export const UiCreatableSelect = FComponent as typeof Select;
