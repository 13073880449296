import { Flex, Text } from '@chakra-ui/react';
import { APIModels } from 'src/shared/api';
import SurveyRadioSatisfaction from '../SurveyRadioSatisfaction/SurveyRadioSatisfaction';
import SurveyCheckbox from '../SurveyCheckbox/SurveyCheckbox';
import SurveyRadio from '../SurveyRadio/SurveyRadio';

interface SurveyQuestionProps {
  question: APIModels.ISurveyQuestionDTO;
  answer: APIModels.IAnswerQuestionDTO;
  onAnswer: (questionId: number, alternatives: APIModels.IAnswerAlternativeDTO[]) => void;
}

export default function SurveyQuestion({ question, answer, onAnswer }: SurveyQuestionProps) {
  function handleChangeAlternative(alternatives: APIModels.IAnswerAlternativeDTO[]) {
    onAnswer(question.id, alternatives);
  }

  function renderAlternatives() {
    switch (question.idTypeQuestion) {
      case APIModels.TypeQuestion['only choice']:
        return (
          <SurveyRadio
            alternatives={question.alternatives}
            selected={answer.answers}
            onChange={handleChangeAlternative}
          />
        );
      case APIModels.TypeQuestion.satisfaction:
        return (
          <SurveyRadioSatisfaction
            alternatives={question.alternatives}
            selected={answer.answers}
            onChange={handleChangeAlternative}
          />
        );
      case APIModels.TypeQuestion['multiple choice']:
        return (
          <SurveyCheckbox
            alternatives={question.alternatives}
            selected={answer.answers}
            onChange={handleChangeAlternative}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <Flex gap={16} flexDir="column">
      <Flex justifyContent="center">
        <Text fontWeight="medium" color="blue.900" fontSize="xl">
          {question.titleAsk}
        </Text>
      </Flex>
      <Flex justifyContent="center" w="full" h="fit-content">
        {renderAlternatives()}
      </Flex>
    </Flex>
  );
}
