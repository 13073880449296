import { Card, Flex, Link as LinkChakra, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useI18n } from 'src/i18n';

const CardWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Card w="full" h="70px" justifyContent="center" alignItems="center" bg="blue.800" cursor="pointer">
      {children}
    </Card>
  );
};

export default function ServicesFooter() {
  const { translate } = useI18n();

  return (
    <Flex bg="gray.150" mt={20} paddingY={10} position="relative">
      <Flex w="full" flexDir="column">
        <Flex w="full" justifyContent="center">
          <Text as="b" fontSize="xl" color="blue.800">
            {translate('dashboard.footer.title')}
          </Text>
        </Flex>
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          w="full"
          justifyContent="center"
          alignItems={{ base: 'center', lg: 'unset' }}
          gap={{ base: 10, lg: 20 }}
          mt={5}
        >
          <Flex w="250px" flexDir="column" textAlign="center">
            <LinkChakra as={Link} to="/services/smartstore">
              <CardWrapper>
                <Flex>
                  <Text as="b" fontSize="2xl" color="white">
                    Smart
                  </Text>
                  <Text as="b" fontSize="2xl" color="yellow.800">
                    Store
                  </Text>
                </Flex>
              </CardWrapper>
              <Text mt={5}>{translate('dashboard.footer.card.smartstore.description')}</Text>
            </LinkChakra>
          </Flex>
          <Flex w="250px" flexDir="column" textAlign="center">
            <LinkChakra as={Link} to="/services/mymdm">
              <CardWrapper>
                <Flex>
                  <Text as="b" fontSize="2xl" color="white">
                    My
                  </Text>
                  <Text as="b" fontSize="2xl" color="yellow.800">
                    MDM
                  </Text>
                </Flex>
              </CardWrapper>
              <Text mt={5}>{translate('dashboard.footer.card.mymdm.description')}</Text>
            </LinkChakra>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
