import { Icon, Divider, CardHeader, Flex, Heading } from '@chakra-ui/react';

export default function SectionTitle({ text, icon }: { text: string; icon: React.ElementType }) {
  return (
    <>
      <CardHeader py={4} mt={3} px={{ base: 0, md: 5 }}>
        <Flex alignItems="center">
          <Icon w={5} h={5} as={icon} color="blue.600" />
          <Heading size="sm" ml={2} fontWeight="700" color="gray.900">
            {text}
          </Heading>
        </Flex>
      </CardHeader>

      <Divider borderColor="blackAlpha.200" size="xl" />
    </>
  );
}
