// https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src
import { DeepPartial, extendTheme, Theme, withDefaultColorScheme } from '@chakra-ui/react';
import components from './components';
import foundations from './foundation';

const styles = {
  global: {
    body: {
      bg: 'white'
    },
    html: {
      fontSize: ['xs', 'md']
    }
  }
};

export const theme = extendTheme(withDefaultColorScheme({ colorScheme: 'blue' }), {
  components,
  ...foundations,
  styles
} as DeepPartial<Theme>);
