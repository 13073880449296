import { createIcon } from '@chakra-ui/icons';

const Signal = createIcon({
  displayName: 'Signal',
  viewBox: '0 0 23 23',
  path: (
    <>
      <path
        d="M16.7708 7.66669C16.7708 7.66669 18.2083 9.10419 18.2083 11.5C18.2083 13.8959 16.7708 15.3334 16.7708 15.3334M19.6458 4.79169C19.6458 4.79169 22.0416 7.18752 22.0416 11.5C22.0416 15.8125 19.6458 18.2084 19.6458 18.2084M6.22908 7.66669C6.22908 7.66669 4.79158 9.10419 4.79158 11.5C4.79158 13.8959 6.22908 15.3334 6.22908 15.3334M3.35409 4.79169C3.35409 4.79169 0.958252 7.18752 0.958252 11.5C0.958252 15.8125 3.35409 18.2084 3.35409 18.2084"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5001 12.4584C11.7542 12.4584 11.998 12.3574 12.1777 12.1777C12.3574 11.9979 12.4584 11.7542 12.4584 11.5C12.4584 11.2459 12.3574 11.0021 12.1777 10.8224C11.998 10.6427 11.7542 10.5417 11.5001 10.5417C11.2459 10.5417 11.0022 10.6427 10.8224 10.8224C10.6427 11.0021 10.5417 11.2459 10.5417 11.5C10.5417 11.7542 10.6427 11.9979 10.8224 12.1777C11.0022 12.3574 11.2459 12.4584 11.5001 12.4584Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
});

const Rocket = createIcon({
  displayName: 'Rocket',
  defaultProps: {
    fill: 'none'
  },
  viewBox: '0 0 23 23',
  path: (
    <>
      <path
        d="M9.58339 16.2917H13.4167L15.3919 9.9705C15.6695 9.08248 15.7276 8.14034 15.561 7.22495C15.3944 6.30956 15.0082 5.44826 14.4354 4.715L11.9534 1.53813C11.8996 1.46923 11.8308 1.41349 11.7523 1.37515C11.6737 1.33682 11.5875 1.31689 11.5001 1.31689C11.4127 1.31689 11.3264 1.33682 11.2479 1.37515C11.1693 1.41349 11.1005 1.46923 11.0468 1.53813L8.56469 4.715C7.99196 5.44826 7.60572 6.30956 7.43914 7.22495C7.27255 8.14034 7.33059 9.08248 7.60827 9.9705L9.58339 16.2917ZM9.58339 16.2917L6.70839 18.2083C6.70839 18.2083 4.79173 14.375 8.14589 11.9792M9.58339 19.1667C9.58339 21.0833 11.5001 22.0417 11.5001 22.0417C11.5001 22.0417 13.4167 21.0833 13.4167 19.1667M15.2673 11.9792C18.6214 14.375 16.7048 18.2083 16.7048 18.2083L13.8298 16.2917"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10.5416C10.9917 10.5416 10.5042 10.3397 10.1448 9.98027C9.78531 9.62082 9.58337 9.13331 9.58337 8.62498C9.58337 8.11665 9.78531 7.62914 10.1448 7.26969C10.5042 6.91025 10.9917 6.70831 11.5 6.70831C12.0084 6.70831 12.4959 6.91025 12.8553 7.26969C13.2148 7.62914 13.4167 8.11665 13.4167 8.62498C13.4167 9.13331 13.2148 9.62082 12.8553 9.98027C12.4959 10.3397 12.0084 10.5416 11.5 10.5416Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
});

const QuestionBubble = createIcon({
  displayName: 'QuestionBubble',
  viewBox: '0 0 12 12',
  defaultProps: {
    fill: 'transparent'
  },
  path: (
    <>
      <g clipPath="url(#clip0_12239_102381)">
        <path
          d="M4.5 4.5C4.5 2.75 7.25 2.75 7.25 4.5C7.25 5.75 6 5.5 6 7M6 9.005L6.005 8.9995"
          stroke="#424242"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1C3.2385 1 1 3.2385 1 6C1 6.9105 1.2435 7.765 1.669 8.5L1.25 10.75L3.5 10.331C4.25977 10.7705 5.12226 11.0013 6 11Z"
          stroke="#424242"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12239_102381">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </>
  )
});

const Speedometer = createIcon({
  displayName: 'Speedometer',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'transparent'
  },
  path: (
    <>
      <path
        d="M11.5013 3.70657V7.41321M11.5013 3.70657C6.20844 3.70657 1.91798 7.85523 1.91798 12.9732C1.91472 14.979 2.58758 16.9312 3.83465 18.5341H8.14715M11.5013 3.70657C16.7942 3.70657 21.0846 7.85523 21.0846 12.9732C21.0846 15.06 20.3716 16.9856 19.168 18.5341L14.8555 18.5331M3.83465 7.41321L6.23048 9.72987M16.7721 9.72987L19.168 7.41321M2.87631 15.7532H5.75131M11.5013 15.7532L12.4596 10.1932M17.2513 15.7532H20.1263"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 21.3132C12.2625 21.3132 12.9938 21.0203 13.5329 20.499C14.0721 19.9776 14.375 19.2705 14.375 18.5332C14.375 17.7959 14.0721 17.0888 13.5329 16.5675C12.9938 16.0461 12.2625 15.7532 11.5 15.7532C10.7375 15.7532 10.0062 16.0461 9.46707 16.5675C8.9279 17.0888 8.625 17.7959 8.625 18.5332C8.625 19.2705 8.9279 19.9776 9.46707 20.499C10.0062 21.0203 10.7375 21.3132 11.5 21.3132Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
});

const IncreaseChart = createIcon({
  displayName: 'IncreaseChart',
  viewBox: '0 0 24 24',
  defaultProps: {
    fill: 'transparent'
  },
  path: (
    <>
      <path
        d="M16 19.339V11.6034M16 11.6034L19 14.5042M16 11.6034L13 14.5042M4 13.5373L12 5.8017L15 8.70255L20 3.8678"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
});

export { Signal, Rocket, QuestionBubble, Speedometer, IncreaseChart };
