import { Image, Link as ChakraLink, useToken } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as Styles from './styles';

export interface NavOptionProps {
  name: string;
  icon: string;
  url: string;
  key: string;
  isSelected: boolean;
  isExternal: boolean;
}

const NavOption: React.FC<NavOptionProps> = ({ url, name, icon, isSelected, isExternal }) => {
  const [white] = useToken('colors', ['white']);
  const opacityHexadecimal = '66'; // 40%
  const backgroundColorActive = `${white}${opacityHexadecimal}`;

  return (
    <ChakraLink as={ReactRouterLink} to={url} isExternal={isExternal}>
      <Styles.Flex role="group" bg={isSelected ? backgroundColorActive : ''}>
        <Image w="20px" h="20px" src={icon} />
        <Styles.TextOption ml={2}>{name}</Styles.TextOption>
      </Styles.Flex>
    </ChakraLink>
  );
};

export default NavOption;
