import { Box, Show } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../../hooks';

import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import * as Styles from './styles';

const WithNav: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isSignedIn } = useUserAuth();
  const location = useLocation();
  const isDashboardRoute = location.pathname === '/dashboard';

  return isSignedIn ? (
    <>
      <NavBar />
      <Styles.Nav data-testid="@navContainer">
        <Show above="md">
          <SideBar />
        </Show>
        <Box
          flex="1"
          overflowY="hidden"
          h="calc(100vh - 64px)"
          bg={isDashboardRoute ? 'gray.150' : 'white'}
          maxW={{
            base: '100%',
            md: 'calc(100% - 74px)'
          }}
        >
          {children}
        </Box>
      </Styles.Nav>
    </>
  ) : (
    <>{children}</>
  );
};
export default WithNav;
