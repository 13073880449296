import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputGroup,
  InputProps,
  InputRightElement,
  InputRightElementProps
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { useMasks } from 'src/shared/hooks';
import UiInput from '../UiInput/UiInput';

type Props = {
  label: string;
  className?: string;
  fieldError: FieldError | undefined;
  dataTestId?: string;
  inputProps: InputProps;
  mask: 'telephone' | 'cnpj' | 'cep';
  formControlProps?: FormControlProps;
  placeholder?: string;
  formLabelProps?: FormLabelProps;
  inputRightElementProps?: InputRightElementProps;
};

export default function InputMaskForm({
  fieldError,
  label,
  formControlProps,
  className,
  inputProps,
  dataTestId,
  mask,
  placeholder,
  formLabelProps,
  inputRightElementProps
}: Props) {
  const { maskToCnpj, maskToTelephone, maskToCEP } = useMasks();

  const onkeyUp = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'cnpj') {
        maskInputCnpj(event);
      }
      if (mask === 'telephone') {
        maskInputTelephone(event);
      }
      if (mask === 'cep') {
        maskInputCEP(event);
      }
    },
    [mask]
  );

  function maskInputCnpj({ currentTarget: target }: React.FormEvent<HTMLInputElement>) {
    target.maxLength = 18;
    target.value = maskToCnpj(target.value);
  }

  function maskInputTelephone({ currentTarget: target }: React.FormEvent<HTMLInputElement>) {
    target.maxLength = 15;
    target.value = maskToTelephone(target.value);
  }

  function maskInputCEP({ currentTarget: target }: React.FormEvent<HTMLInputElement>) {
    target.maxLength = 15;
    target.value = maskToCEP(target.value);
  }

  return (
    <FormControl {...formControlProps} isInvalid={!!fieldError} className={className}>
      <FormLabel color="gray.900" {...formLabelProps}>
        {label}
      </FormLabel>

      <InputGroup>
        <UiInput {...inputProps} data-testid={dataTestId} onKeyUp={onkeyUp} placeholder={placeholder} />

        {inputRightElementProps && <InputRightElement {...inputRightElementProps} />}
      </InputGroup>

      {fieldError && <FormErrorMessage>{fieldError?.message}</FormErrorMessage>}
    </FormControl>
  );
}
