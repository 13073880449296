export default function useMasks() {
  function maskToTelephone(toMask: string) {
    const maxLength = 15;
    return toMask
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d+)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})[-](\d)(\d{4})/, '$1$2-$3')
      .slice(0, maxLength);
  }

  function maskToCnpj(toMask: string) {
    const maxLength = 18;

    return toMask
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})\.(\d{3})(\d)/, '$1.$2/$3')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .slice(0, maxLength);
  }

  function maskToCEP(toMask: string) {
    const maxLength = 9;
    return toMask
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .slice(0, maxLength);
  }

  return { maskToTelephone, maskToCnpj, maskToCEP };
}
